import classNames from 'classnames'
import qs from 'qs'
import React from 'react'
import ButtonCopyToClipboard from 'components/ButtonCopyToClipboard'
import Container from 'components/Container'
import EmailForm from 'components/EmailForm'
import FacebookShareButton from 'components/FacebookShareButton'
import Heading from 'components/Heading'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Tag from 'components/Tag'
import Text from 'components/Text'
import Title from 'components/Title'
import * as events from 'constants/events'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as environment from 'libs/environment'
import * as notifications from 'libs/notifications'
import * as url from 'libs/url'
import * as userApiRequest from 'libs/user-api-request'
import styles from './Referral.module.scss'

type Props = {
  onSuccess?: () => void
} & Component

export default function Referral({ className, onSuccess }: Props) {
  const globalContext = useGlobalContext()

  async function submit(values, formikActions) {
    const response = await userApiRequest.sendReferralInvite(values.email).catch(() => {
      console.error('Something went wrong. Please try again later.')
    })

    notifications.notifySuccess(response.data.message)
    formikActions.resetForm()

    if (onSuccess) onSuccess()
  }

  function handleSubmit(values, formikActions) {
    submit(values, formikActions)

    globalContext.analytics?.trackEvent(events.REFERRALS_SEND_BUTTON_CLICK, {
      referralEmail: values.email,
    })
  }

  if (!globalContext.user) return null

  const urlUnique = `${environment.DOMAIN}?${qs.stringify({
    referral: globalContext.user.id,
  })}`

  return (
    <Text color="white" element="div" className={classNames('Refer', styles.this, className)}>
      <Container flush size="medium">
        <Row size="small">
          <Tag color="blue">Referrals</Tag>
        </Row>
        <Title
          subtext="Invite a friend to get an extra month of Bold Premium when they sign up!"
          align="left">
          Refer a friend, get 1 month free
        </Title>
        <Row>
          <Heading levelStyle={4}>How it works</Heading>
          <Text element="ol" className={styles.list}>
            {[
              'Send an invite directly to their email, or share your referral link below.',
              'Your referral will get a free month of Bold.',
              '🎉 And that’s it! Once they sign up, you’ll be notified and receive 30 days of Bold Premium.',
            ].map((item) => (
              <Text element="li">
                <Text element="p" flush>
                  {item}
                </Text>
              </Text>
            ))}
          </Text>
        </Row>
        <Row>
          <Heading levelStyle={4}>Tell your friends</Heading>
          <Container align="left" flush size="small">
            <EmailForm
              inline
              buttonProps={{
                children: 'Send',
              }}
              fieldProps={{
                labelHidden: true,
                placeholder: 'mail@address.com',
              }}
              onSubmit={handleSubmit}
            />
            <FacebookShareButton
              full
              href={urlUnique}
              quote="Join me on Bold and get one month of free access to hundreds of at-home workouts and live classes on Zoom."
            />
          </Container>
        </Row>
        <Text copy element="div" size="small">
          Or directly send them your unique invite URL
          <br />
          <MediaObject
            center
            figure={
              <ButtonCopyToClipboard
                copy={urlUnique}
                notification="Referral URL copied to your clipboard"
                size="xsmall"
                onClick={() => {
                  globalContext.analytics?.trackEvent(events.REFERRALS_COPY_URL_BUTTON_CLICK)
                }}
              />
            }
            figurePosition="right"
            inline
            size="xsmall">
            <Text element="u">{url.format(urlUnique)}</Text>
          </MediaObject>
        </Text>
      </Container>
    </Text>
  )
}
