import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import pluralize from 'pluralize'
import qs from 'qs'
import React from 'react'
import Button from 'components/Button'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import type { Component, Playlist as PlaylistType } from 'constants/types'
import LessonTrainers from 'features/lesson/components/LessonTrainers'
import type { Lesson } from 'libs/lesson'
import * as lessonApiRequest from 'libs/lesson-api-request'
import * as url from 'libs/url'
import styles from './Playlist.module.scss'

type Props = {
  blogs: {}[]
  data: PlaylistType
} & Component

export default function Playlist({ blogs, className, data }: Props) {
  const location = useLocation()
  const [lessonsData, setLessonsData] = React.useState<Lesson[]>()
  const [lessonsUnique, setLessonsUnique] = React.useState<Lesson[]>()

  React.useEffect(() => {
    async function getData() {
      const response = await lessonApiRequest.getPlaylist(data.id).catch((error: any) => {
        console.error(error)
      })

      setLessonsData(response.data?.lessons)

      const trainersUnique = response.data?.lessons?.filter(
        (lesson, index, self) =>
          index === self.findIndex((l) => l.instructorName === lesson.instructorName)
      )

      setLessonsUnique(trainersUnique)
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleClick() {
    navigate(`${paths.PLAYLIST}${data.id}/`, {
      state: {
        from: `${location.pathname}?${qs.stringify(url.getQueryString())}`,
        lessons: lessonsData,
        playlist: data,
      },
    })
  }

  const collectionName = data.title

  return (
    <button
      type="button"
      className={classNames('Playlist', styles.this, className)}
      onClick={handleClick}>
      <Row size="xxsmall" className={styles['box-row']}>
        <div className={styles.box}>
          <Text color="white" element="p" lines={4} className={styles.description}>
            {data.description}
          </Text>
          <footer className={styles['box--footer']}>
            <Button color="white" faux size="small" className={styles['items--button']}>
              View collection
            </Button>
            <LessonTrainers data={lessonsUnique} />
          </footer>
        </div>
      </Row>
      <Row size="xxxsmall">
        <Text element="h3" weight="bold" className={styles.title}>
          {collectionName}
        </Text>
      </Row>
      <Text element="div" size="small">
        {pluralize('class', data.expected_duration_days ?? data.expectedDurationDays, true)}
        {!_.isEmpty(blogs) && (
          <>
            {' • '}
            {pluralize('article', _.size(blogs), true)}
          </>
        )}
      </Text>
    </button>
  )
}
