import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import HeaderSubheader from 'components/HeaderSubheader'
import Layout from 'components/Layout'
import Loader from 'components/Loader'
import View from 'components/View'
import type { Component } from 'constants/types'
import styles from './ExploreLessonsWithoutFilters.module.scss'

type Props = {
  children: React.ReactNode
  loading: boolean
  title: string
} & Component

export default function ExploreLessonsWithoutFilters({
  children,
  className,
  loading,
  title,
}: Props) {
  return (
    <Layout className={classNames('ExploreLessonsWithoutFilters', className)}>
      <Head bodyAttributes={{ class: 'body---grey body---has-subheader' }} />
      <HeaderSubheader title={title} />
      <View color="grey">
        <Container flush size="xxlarge">
          {loading ? <Loader overlay={false} /> : <div className={styles.grid}>{children}</div>}
        </Container>
      </View>
    </Layout>
  )
}
