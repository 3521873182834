import classNames from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingCheckinSingleForm from 'features/onboarding/components/OnboardingCheckinSingleForm'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as notifications from 'libs/notifications'
import CheckinTracker from 'libs/checkins/checkin-tracker'
import styles from './PelvicAssessment.module.scss'

export default function PelvicAssessment() {
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker>(null)
  const [showModal, setShowModal] = React.useState(false)
  const globalContext = useGlobalContext()

  React.useEffect(() => {
    async function getCheckin() {
      const checkinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'program_change',
          code: 'pelvic_health_program_change',
          activeOnly: true,
          createIfNotExists: true,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'pelvic_health_program_change',
            type: 'program_change',
          })
        })
      if (checkinResponse.data) {
        setCheckinTracker(
          new CheckinTracker(globalContext.user.id, checkinResponse.data, globalContext.analytics)
        )
      }
    }
    getCheckin()
  }, [globalContext.analytics, globalContext.user.id])

  async function handleSuccess() {
    try {
      notifications.notifySuccess('Successfully completed')
      navigate(`${paths.ONBOARDING_ASSESSMENT_RESULTS}?pelvic=true`, { replace: true })
    } catch {
      notifications.notifyError(
        'Something went wrong submitting your responses. Please try again later.'
      )
    }
  }

  function handleError() {
    notifications.notifyError(
      'Something went wrong submitting your responses. Please try again later.'
    )
  }

  function handleShowModal() {
    setShowModal(true)
  }

  function goBackToProgram() {
    try {
      checkinApiRequest.markUserCheckinAsDismissed(checkinTracker.checkinId)
      navigate(paths.PROGRAM)
    } catch {
      navigate(paths.PROGRAM)
    }
  }

  return (
    <Layout
      headerProps={{ nav: false }}
      footer={false}
      className={classNames('PelvicAssessment', styles.this)}>
      <Head bodyAttributes={{ class: 'body---hide-help-button' }} />
      <Modal size="medium" isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <ModalBox
          title="Are you sure you want to return to your program?"
          subtext="If you go back to your program, you will lose your progress in this assessment."
          titleAlign="left">
          <Buttons align="center" equal>
            <Button color="white" onClick={() => setShowModal(false)}>
              No, never mind
            </Button>
            <Button onClick={goBackToProgram}>Yes, go back to my program</Button>
          </Buttons>
        </ModalBox>
      </Modal>
      <View className={styles.main}>
        <Container flush size="small">
          <OnboardingCheckinSingleForm
            checkinTracker={checkinTracker}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        </Container>
      </View>
      <footer className={styles.footer}>
        <Container full>
          Changed your mind?{' '}
          <Button color="purple" level="text" onClick={handleShowModal}>
            Return to current program
          </Button>
        </Container>
      </footer>
    </Layout>
  )
}
