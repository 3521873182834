import { graphql, navigate, useStaticQuery } from 'gatsby'
import React from 'react'
import Button from 'components/Button'
import Card from 'components/Card'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import ExploreSection from 'features/explore/components/ExploreSection'

export default function ExploreBlogPosts() {
  const globalContext = useGlobalContext()
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: {
            draft: { ne: true }
            hideFromExplore: { ne: true }
            hideFromLanding: { ne: true }
            template: { eq: "blog" }
          }
        }
        limit: 4
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 290, maxHeight: 174) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
          }
        }
      }
    }
  `)

  async function handleButtonClick() {
    await globalContext.analytics?.trackEvent('Go to blog button click', {
      from: 'explore',
    })
    navigate(paths.BLOG)
  }

  function handleClick(path: string) {
    navigate(path, {
      state: {
        from: 'explore',
      },
    })
  }

  return (
    <ExploreSection
      title="Learn about healthy aging on the Bold blog"
      action={
        <Button color="white" target="_blank" onClick={handleButtonClick}>
          Go to the blog
        </Button>
      }
      className="ExploreBlogPosts">
      {data?.allMdx?.nodes?.map((item) => (
        <Card
          title={item.frontmatter.title}
          image={item.frontmatter.image}
          key={item.id}
          onClick={() => handleClick(item.fields.slug)}
        />
      ))}
    </ExploreSection>
  )
}
