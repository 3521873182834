import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Heading from 'components/Heading'
import MassAdvantageInformationButton from 'components/MassAdvantageInformationButton'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Tag from 'components/Tag'
import Text, { type TextProps } from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import Title from 'components/Title'
import * as events from 'constants/events'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingLayout from 'features/onboarding/components/OnboardingLayout'
import OnboardingView from 'features/onboarding/components/OnboardingView'
import SubscriptionPayment from 'features/user/components/SubscriptionPayment'
import SubscriptionPlanPrice from 'features/user/components/SubscriptionPlanPrice'
import SubscriptionPlans from 'features/user/components/SubscriptionPlans'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { generateFbc } from 'libs/analytics'
import * as cookie from 'libs/cookie'
import * as eventTracking from 'libs/event-tracking'
import * as notifications from 'libs/notifications'
import * as subscriptionApiRequest from 'libs/subscription-api-request'
import * as subscriptions from 'libs/subscriptions'
import * as userApiRequest from 'libs/user-api-request'
import styles from './OnboardingPayment.module.scss'
import { ReactComponent as CardSvg } from './images/card.svg'
import { ReactComponent as CheckmarkCircleSvg } from './images/checkmark---circle.svg'
import { ReactComponent as InformationSvg } from './images/information.svg'

export default function OnboardingPayment() {
  useEventPageVisited(events.ONBOARDING_PAYMENT_PAGE)
  const globalContext = useGlobalContext()
  const [isPlanSelected, setIsPlanSelected] = React.useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [paymentError, setPaymentError] = React.useState(false)

  function trackEvent(eventName: string) {
    globalContext.analytics?.trackEvent(eventName, {
      amount_to_be_paid: globalContext.selectedPlan.amount / 100,
      from: 'onboarding',
      selectedPlanName: globalContext.selectedPlan?.name,
      srcId: globalContext.user?.srcId,
    })
  }

  async function finishOnboarding() {
    const fbclid = cookie.getCookie(storage.FB_CLID) || null

    await userApiRequest.updateUser({
      isOnboardingCompleted: true,
      onboardingCompletedDate: new Date(),
    })
    await globalContext.analytics?.trackEvent(
      events.ONBOARDING_COMPLETE,
      {
        external_id: globalContext.user?.id,
        fbc: generateFbc(fbclid),
        from: globalContext.user?.loginType,
      },
      {
        fbclid,
        userId: globalContext.user?.id,
      }
    )
    eventTracking.onboardingComplete()
    await globalContext.updateUser()
    setIsSubmitted(true)
  }

  async function handleError(error: any, eventName: string) {
    if (paymentError) {
      trackEvent(events.PAYMENT_ONBOARDING_FAIL_SECOND_TRY)
      await subscriptionApiRequest.subscribeUserToPlan({
        freeTrialDays: subscriptions.TRIAL_DAYS,
        stripePlanId: globalContext.selectedPlan?.id,
      })
      await finishOnboarding()
    } else {
      setIsSubmitting(false)
      setPaymentError(true)
      console.error(error)
      trackEvent(eventName)
      trackEvent(events.PAYMENT_ONBOARDING_FAIL_FIRST_TRY)
      // TODO: delete when text above button is finished
      notifications.notifyError(
        'We were unable to process your payment. Please double check your credit card information and try again.'
      )
    }
  }

  async function createSubscription() {
    const response = await subscriptionApiRequest.subscribeUserToPlan({
      freeTrialDays: subscriptions.TRIAL_DAYS,
      stripePlanId: globalContext.selectedPlan?.id,
    })

    if (response.statusCode === statusCodes.SUCCESS) {
      await finishOnboarding()
      trackEvent(events.PURCHASE_ONBOARDING)
    } else {
      await handleError(response, events.PAYMENT_FAILED_ONBOARDING)
    }
  }

  async function submit(token) {
    if (!globalContext.user.stripeCustomerId) {
      const createCustomerResponse = await userApiRequest.createStripeCustomerForUser({
        email: globalContext.user.email,
        stripePaymentSourceToken: '',
      })

      if (createCustomerResponse.statusCode !== statusCodes.POST_SUCCESS) {
        console.error(createCustomerResponse)
        notifications.notifyError('Something went wrong. Please try again later.')
        trackEvent(events.STRIPE_CUSTOMER_CREATION_FAILURE_ONBOARDING)
        return
      }

      await globalContext.updateUser()
    }

    try {
      const cardResponse = await subscriptionApiRequest.addDefaultCardForUser(token.id)
      await (cardResponse.code === statusCodes.SUCCESS
        ? createSubscription(cardResponse.data.card)
        : handleError(cardResponse, events.CARD_ADD_FAILED_ONBOARDING))
    } catch (error) {
      handleError(error, events.CARD_ADD_FAILED_ONBOARDING)
    }
  }

  function handlePaymentMethodFormValidityChange(isValid: boolean) {
    setIsSubmitDisabled(!isValid)
  }

  function handlePaymentMethodFormSuccess(token) {
    submit(token)
  }

  function handleSubmit() {
    setIsSubmitting(true)
    trackEvent(events.ADD_PAYMENT_INFO_ONBOARDING)
  }

  function handleContinueButtonClick() {
    // TODO: this probably isn't necessary any longer now that components have been decoupled
    //hack: force button click execution to complete before setting isPlanSelected to true
    //so form submission doesn't happen prematurely
    setTimeout(() => setIsPlanSelected(true), 0)
    setIsSubmitDisabled(true)
    trackEvent(events.SELECTED_PLAN_ONBOARDING)
  }

  function handleBackButtonClick() {
    setIsPlanSelected(false)
    setIsSubmitDisabled(false)
    setPaymentError(false)
  }

  const commonProps = {
    className: 'OnboardingPayment',
    // TODO: not sure this should be shared
    redirectIfOnboardingComplete: false,
  }

  if (isSubmitted) {
    return (
      <OnboardingLayout {...commonProps}>
        <Container flush size="medium">
          <Box color="white" size="large">
            <Title
              subtext={
                paymentError
                  ? 'We had an issue processing your payment. Please contact your benefit provider to verify your card information. You can update your card information at any time in settings.'
                  : 'You’ve taken the first step towards improving your strength, balance, and mobility. Now, let’s take a look at your personalized Bold program.'
              }
              actions={
                <Button to={paths.PROGRAM} size="large">
                  {paymentError ? 'Continue to my 2-week trial' : 'View my program'}
                </Button>
              }
              flush>
              Welcome to Bold, {globalContext.user.firstName}
            </Title>
          </Box>
        </Container>
      </OnboardingLayout>
    )
  }

  const cardBox = (
    <Text color="white" element="div" className={styles['mass-advantage']}>
      <Row size="smallx">
        <MediaObject figure={<CardSvg />} size="xxsmall">
          <Row size="xxxsmall">
            <TextWithIcon
              icon={
                <MassAdvantageInformationButton>
                  <InformationSvg />
                </MassAdvantageInformationButton>
              }
              center
              iconPosition="right"
              inline
              size="large"
              weight="bold">
              Eligible for Flex Card dollars
            </TextWithIcon>
          </Row>
          <Text element="p" flush>
            Bold is a preferred health and wellness partner of Mass Advantage
          </Text>
        </MediaObject>
      </Row>
      <Text align="center" element="div">
        <StaticImage
          src="./images/cards@2x.png"
          width={285}
          alt="Mass Advantage Flex Card"
          placeholder="blurred"
        />
      </Text>
    </Text>
  )

  const paymentMethodFormId = 'stripePaymentForm'

  const buttonProps = {
    full: true,
    size: 'large',
  }

  const textFooterProps: Partial<TextProps> = {
    color: 'ink200',
    element: 'p',
    flush: true,
    size: 'small',
  }

  if (isPlanSelected) {
    return (
      <OnboardingLayout {...commonProps}>
        <OnboardingView
          prepend={cardBox}
          small="Confirm plan"
          title="Enter your payment details."
          onBack={handleBackButtonClick}>
          <Row size="xmedium">
            <Box color="white" size="small">
              <MediaObject figure={<CheckmarkCircleSvg />} center size="mediumx">
                <Row size="xxxsmall">
                  <MediaObject
                    figure={
                      <Tag casing="capitalize" color="purple100" weight="bold">
                        {globalContext.selectedPlan?.billingFrequency}
                      </Tag>
                    }
                    center
                    figurePosition="right"
                    inline
                    size="xxsmall">
                    <Text element="h3" weight="bold">
                      Bold {subscriptions.getPlanName(globalContext.selectedPlan)}
                    </Text>
                  </MediaObject>
                </Row>
                <Text element="div" size="small">
                  <Text weight="bold">2-week free trial</Text> then{' '}
                  <SubscriptionPlanPrice
                    data={globalContext.selectedPlan.amount / 100}
                    align="inherit"
                    appendInline={`/${globalContext.selectedPlan.interval}`}
                    size="inherit"
                    weight="normal"
                  />
                </Text>
              </MediaObject>
            </Box>
          </Row>
          <Heading level={2} levelStyle={5}>
            Payment details
          </Heading>
          <Row size="small">
            <SubscriptionPayment
              formId={paymentMethodFormId}
              onSuccess={handlePaymentMethodFormSuccess}
              onValidityChange={handlePaymentMethodFormValidityChange}
            />
            <Button
              {...buttonProps}
              disabled={isSubmitDisabled}
              form={paymentMethodFormId}
              loading={isSubmitting}
              type="submit"
              onClick={handleSubmit}>
              Checkout
            </Button>
          </Row>
          <Text {...textFooterProps}>
            Your plan will auto-renew at the end of each billing cycle. Manage your plan selection
            in Settings. All sales final.
          </Text>
        </OnboardingView>
      </OnboardingLayout>
    )
  }

  return (
    <OnboardingLayout {...commonProps}>
      <OnboardingView
        prepend={cardBox}
        small="Select plan"
        title="Unlock your personalized program with a free, 2-week trial.">
        <Row size="small">
          <Row size="small">
            <SubscriptionPlans hidePremiumFeaturesBox />
          </Row>
          <Button {...buttonProps} onClick={handleContinueButtonClick}>
            Continue to checkout
          </Button>
        </Row>
        <Text {...textFooterProps}>
          After your trial period ends, your Flex Card will be charged according to your chosen
          payment plan. You may cancel any time before your trial ends.
        </Text>
      </OnboardingView>
    </OnboardingLayout>
  )
}
