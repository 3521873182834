import { datadogLogs } from '@datadog/browser-logs'
import React from 'react'
import Layout from 'components/Layout'
import Text from 'components/Text'
import View from 'components/View'
import { RELEASE_VERSION } from 'constants/generated-release-version'
import * as environment from 'libs/environment'

type Props = {
  children: React.ReactNode
}

type State = {
  hasError: boolean
}

if (environment.isProduction || environment.isStaging) {
  datadogLogs.init({
    clientToken: environment.isProduction
      ? 'pub9b6324ed105b2bf64f19c816a64b011f'
      : 'pubb664ce9f182209656cfa785176d3bbb3',
    env: environment.isProduction ? 'production' : 'staging',
    forwardConsoleLogs: ['error', 'warn', 'info', 'log'],
    forwardErrorsToLogs: true,
    service: 'web',
    sessionSampleRate: 100,
    site: 'us5.datadoghq.com',
    version: RELEASE_VERSION,
  })
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(error: Error): State {
    console.error('ErrorBoundary error:', error)

    return {
      hasError: true,
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary uncaught error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout className="ErrorBoundary">
          <View>
            <Text align="center" copy>
              Something went wrong. Please refresh your page and try again.
            </Text>
          </View>
        </Layout>
      )
    }

    return this.props.children
  }
}
