import _ from 'lodash'
import React from 'react'
import Toggle from 'react-toggle'
import Container from 'components/Container'
import Field from 'components/Field'
import Label from 'components/Label'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import AdminLayout from 'features/admin/components/AdminLayout'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as enterpriseLib from 'libs/enterprise'
import * as logging from 'libs/logging'
import * as notifications from 'libs/notifications'
import * as url from 'libs/url'

type Props = {
  enterpriseMemberId: string
}

export default function AdminEnterpriseMemberProfile({ enterpriseMemberId }: Props) {
  const globalContext = useGlobalContext()
  const [enterpriseMember, setEnterpriseMember] = React.useState(null)
  const [enterpriseKey, setEnterpriseKey] = React.useState(null)
  const [carelinxConsent, setCarelinxConsent] = React.useState(false)
  const [grouperDoNotCall, setGrouperDoNotCall] = React.useState(false)
  const [grouperNotAtThisNumber, setGrouperNotAtThisNumber] = React.useState(false)

  React.useEffect(() => {
    async function getData() {
      const { enterpriseMemberId: queryEnterpriseMemberId } = url.getQueryString()
      const enterpriseMemberResult = await adminApiRequest.getEnterpriseMember(
        queryEnterpriseMemberId ?? enterpriseMemberId
      )
      const apiCallEnterpriseMember = enterpriseMemberResult?.data
      setEnterpriseMember(apiCallEnterpriseMember)
      setEnterpriseKey(apiCallEnterpriseMember?.enterprise?.internalId)
      setCarelinxConsent(apiCallEnterpriseMember?.configuration?.carelinxConsent)
      setGrouperDoNotCall(apiCallEnterpriseMember?.configuration?.grouperDoNotCall)
      setGrouperNotAtThisNumber(apiCallEnterpriseMember?.configuration?.grouperNotAtThisNumber)
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function updateConfiguration(fieldName: string, previousValue: boolean) {
    const APPLICABLE_FIELD_NAMES = ['carelinxConsent', 'grouperDoNotCall', 'grouperNotAtThisNumber']
    if (!_.includes(APPLICABLE_FIELD_NAMES, fieldName)) {
      logging.toDatadog(`updateConfiguration field name ${fieldName} not supported`)
      return
    }
    const newValue = !previousValue
    const newConfiguration = { [`${fieldName}`]: newValue }
    const response = await adminApiRequest.updateEnterpriseMemberConfiguration(
      enterpriseMember.id,
      newConfiguration
    )
    if (response.statusCode !== statusCodes.POST_SUCCESS) {
      const message = response.message || response.error.message
      notifications.notifyError(message)
      return
    }

    notifications.notifySuccess('Enterprise member field updated!')
    let eventName = null
    const eventBody: any = {
      admin: globalContext.user.email,
      affectedEnterpriseMemberId: enterpriseMember.id,
    }
    switch (fieldName) {
      case 'carelinxConsent': {
        setCarelinxConsent(newValue)
        eventName = 'Admin updated enterprise member carelinx consent'
        eventBody.carelinxConsent = newValue
        break
      }
      case 'grouperDoNotCall': {
        setGrouperDoNotCall(newValue)
        eventName = 'Admin updated enterprise member grouper program opt out'
        eventBody.grouperDoNotCall = newValue
        break
      }
      case 'grouperNotAtThisNumber': {
        setGrouperNotAtThisNumber(newValue)
        eventName = 'Admin updated enterprise member grouper not at this number'
        eventBody.grouperNotAtThisNumber = newValue
        break
      }
      default: {
        // should not reach here because of earlier return
        logging.toDatadog(
          `updateConfiguration field name ${fieldName} not supported - cannot set state variable`
        )
        break
      }
    }

    if (eventName) globalContext.analytics?.trackEvent(eventName, eventBody)
  }

  if (!enterpriseMember) return null

  return (
    <AdminLayout className="AdminEnterpriseMemberProfile">
      <View>
        <Container align="left" size="small">
          <Row>
            <Text size="large" weight="medium">
              Enterprise Member Info (currently can only update UHC Group specific items)
            </Text>
          </Row>
          <Row>
            <Text size="small">
              {enterpriseMember?.firstName} {enterpriseMember?.lastName}
            </Text>
          </Row>
          <Row>
            <Text size="small">DOB: {enterpriseMember?.dob}</Text>
          </Row>
          <Row>
            <Text size="small">Email: {enterpriseMember?.email}</Text>
          </Row>
          <Row>
            <Text size="small">Phone: {enterpriseMember?.phone}</Text>
          </Row>
          {enterpriseKey === enterpriseLib.GROUPER_KEY && (
            <>
              <Field>
                <Label>(If applicable) Consents to Carelinx</Label>
                <Toggle
                  checked={carelinxConsent}
                  icons={false}
                  onChange={() => updateConfiguration('carelinxConsent', carelinxConsent)}
                />
              </Field>
              <Field>
                <Label>(If applicable) Grouper Call Opt Out (No Calls from UHC)</Label>
                <Toggle
                  checked={grouperDoNotCall}
                  icons={false}
                  onChange={() => updateConfiguration('grouperDoNotCall', grouperDoNotCall)}
                />
              </Field>
              <Field>
                <Label>(If applicable) Grouper Wrong Contact Information</Label>
                <Toggle
                  checked={grouperNotAtThisNumber}
                  icons={false}
                  onChange={() =>
                    updateConfiguration('grouperNotAtThisNumber', grouperNotAtThisNumber)
                  }
                />
              </Field>
            </>
          )}
        </Container>
      </View>
    </AdminLayout>
  )
}
