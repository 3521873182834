import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import Tags from 'components/Tags'
import type { Component, Playlist } from 'constants/types'
import type { Lesson } from 'libs/lesson'
import * as lessonApiRequest from 'libs/lesson-api-request'

type Props = {
  lessons: Lesson[]
  id: string
} & Component

export default function PlaylistTags({ className, id, lessons }: Props) {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: { draft: { ne: true }, playlists: { ne: null }, template: { eq: "blog" } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 290, maxHeight: 174) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            playlists
            title
          }
        }
      }
    }
  `)
  const [playlist, setPlaylist] = React.useState<Playlist>()

  React.useEffect(() => {
    async function getPlaylist() {
      if (_.isEmpty(playlist) || _.isEmpty(lessons)) {
        const response = await lessonApiRequest.getPlaylist(id).catch((error: any) => {
          console.error(error)
        })
        setPlaylist(response.data?.playlist)
      }
    }
    getPlaylist()
  }, [id, lessons, playlist])

  const blogs = data?.allMdx?.nodes?.filter((item) => item.frontmatter.playlists?.includes(id))
  const tags = playlist?.tags?.filter(
    (item) => item !== 'default' && item !== 'HOS' && item !== 'clinical'
  )

  return (
    <Tags
      data={[
        pluralize('class', _.size(lessons), true),
        !_.isEmpty(blogs) ? pluralize('article', _.size(blogs), true) : undefined,
        !_.isEmpty(tags) ? tags.join(', ') : undefined,
      ]}
      className={classNames('PlaylistTags', className)}
    />
  )
}
