import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Media from 'react-media'
import Heading from 'components/Heading'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Tag from 'components/Tag'
import Text from 'components/Text'
import * as challenge from 'libs/challenge'
import { useUserChallenges } from 'libs/challenge-query'
import constants from 'styles/constants.module.scss'
import styles from './DashboardToday.module.scss'
import DashboardTodayLessonLearn from './components/DashboardTodayLessonLearn'
import DashboardTodayLessonMove from './components/DashboardTodayLessonMove'
import { ReactComponent as CompleteBannerSvg } from './images/complete-banner.svg'

export default function DashboardToday() {
  const { data: challenges, isLoading } = useUserChallenges(
    {
      isChallengeActive: true,
      status: 'active',
      type: 'daily',
    },
    {
      revalidateOnFocus: true,
    }
  )
  const challengesData = challenges?.data
  const headerRef = React.createRef()
  const [isHeaderStuck, setIsHeaderStuck] = React.useState(false)

  React.useEffect(() => {
    const headerRefCurrent = headerRef.current

    if (!headerRefCurrent) return

    const observer = new IntersectionObserver(
      ([entry]) => setIsHeaderStuck(entry.intersectionRatio < 1),
      {
        threshold: [1],
      }
    )
    observer.observe(headerRefCurrent)

    return () => observer.unobserve(headerRefCurrent)
  }, [headerRef])

  if (!challengesData || _.isEmpty(challengesData)) {
    return null
  }

  const learnChallenge = challengesData.find(
    (item: any) =>
      item.tag === challenge.CHALLENGE_TAG.LEARN ||
      item.challenge?.tag === challenge.CHALLENGE_TAG.LEARN
  )
  const moveChallenge = challengesData.find(
    (item: any) =>
      item.tag === challenge.CHALLENGE_TAG.MOVE ||
      item.challenge?.tag === challenge.CHALLENGE_TAG.MOVE
  )

  return (
    <section className="DashboardToday">
      <header
        ref={headerRef}
        className={classNames(styles.header, isHeaderStuck && styles['header---stuck'])}>
        <MediaObject
          figure={
            <Media query={{ minWidth: constants.MOBILE_MEDIUM }}>
              <Tag color="grey" level="secondary" size="xsmall">
                Recommended for you
              </Tag>
            </Media>
          }
          center
          figurePosition="right"
          inline
          size="mediumx">
          <Heading flush level={2} levelStyle={4}>
            Today’s plan
          </Heading>
        </MediaObject>
      </header>
      <Row size="small">
        <div className={styles.lessons}>
          {isLoading ? (
            <Loader overlay={false} />
          ) : (
            <>
              <DashboardTodayLessonMove challenge={moveChallenge} key={moveChallenge?.lessonId} />
              <DashboardTodayLessonLearn
                challenge={learnChallenge}
                key={learnChallenge?.lessonId}
              />
            </>
          )}
        </div>
      </Row>
      {learnChallenge?.complete && moveChallenge?.complete && (
        <Text align="center">
          <CompleteBannerSvg aria-label="Great job — 2/2 complete" />
        </Text>
      )}
    </section>
  )
}
