import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Divider from 'components/Divider'
import Heading from 'components/Heading'
import View from 'components/View'
import paths from 'constants/paths'
import AdminLayout from 'features/admin/components/AdminLayout'
import * as notifications from 'libs/notifications'
import AdminEnterpriseUsageReporting from './components/AdminEnterpriseUsageReporting'
import AdminEnterpriseUserConvert from './components/AdminEnterpriseUserConvert'
import AdminEnterpriseUserMigrate from './components/AdminEnterpriseUserMigrate'
import AdminEnterpriseUserRemove from './components/AdminEnterpriseUserRemove'

export default function AdminEnterprise() {
  return (
    <AdminLayout className="AdminEnterprise">
      <View>
        <Container flush>
          <Heading level={1}>Enterprise</Heading>
          <Buttons>
            <Button to={paths.ADMIN_ENTERPRISE_MEMBER_SEARCH} color="white">
              Search for Enterprise Member
            </Button>
          </Buttons>
          <Divider />
          <AdminEnterpriseUsageReporting
            onSuccess={() => {
              notifications.notifySuccess('Report downloaded')
            }}
          />
          <Divider />
          <AdminEnterpriseUserConvert
            onSuccess={() => {
              notifications.notifySuccess('User converted')
            }}
            onError={() => {
              notifications.notifyError('Failed to convert user')
            }}
          />
          <Divider />
          <AdminEnterpriseUserRemove
            onSuccess={() => {
              notifications.notifySuccess('User removed')
            }}
            onError={() => {
              notifications.notifyError('Failed to remove user')
            }}
          />
          <Divider />
          <AdminEnterpriseUserMigrate
            onSuccess={() => {
              notifications.notifySuccess('User migrated')
            }}
            onError={() => {
              notifications.notifyError('Failed to migrate user')
            }}
          />
        </Container>
      </View>
    </AdminLayout>
  )
}
