import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import * as rdd from 'react-device-detect'
import Button from 'components/Button'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Text from 'components/Text'
import * as events from 'constants/events'
import storage from 'constants/storage'
import type { Component } from 'constants/types'
import urls from 'constants/urls'
import { useGlobalContext } from 'contexts/GlobalContext'
import useIp from 'hooks/use-ip'
import * as api from 'libs/api'
import * as cookie from 'libs/cookie'
import * as user from 'libs/user'

type Props = Component

export default function AppDownloadModal({ className }: Props) {
  const globalContext = useGlobalContext()
  const ipRef = useIp()
  const [isOpen, setIsOpen] = React.useState(false)

  const isAndroid = rdd.isAndroid

  React.useEffect(() => {
    const isEligibleForModal =
      user.isEnterpriseFitnessUser(globalContext.user) && rdd.isMobile && (rdd.isIOS || isAndroid)

    async function track() {
      if (
        !isEligibleForModal ||
        cookie.getCookie(storage.MOBILE_APP_DOWNLOAD_MODAL_KEY) === storage.MODAL_DISMISSED
      ) {
        return
      }

      await globalContext.analytics?.trackEvent('App download modal shown')
      setIsOpen(true)
    }
    track()
  }, [globalContext.analytics, globalContext.user, isAndroid])

  async function registerIpForDownload() {
    if (!ipRef.current) return

    await api.post('registerIpForAppDownloadAuthed', {
      ipAddress: ipRef.current,
    })
  }

  async function close() {
    cookie.createCookie(storage.MOBILE_APP_DOWNLOAD_MODAL_KEY, storage.MODAL_DISMISSED)
    setIsOpen(false)

    await globalContext.analytics?.trackEvent('App download modal dismissed')
  }

  function handleDownloadClick() {
    globalContext.analytics?.trackEvent(events.DOWNLOAD_APP_CLICK, {
      page: 'Program',
      platform: isAndroid ? 'Android' : 'iOS',
    })
    registerIpForDownload()
    window.open(isAndroid ? urls.GOOGLE_PLAY_STORE : urls.APPLE_APP_STORE, '_blank')
    close()
  }

  function handleRequestClose() {
    close()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="small"
      className={classNames('AppDownloadModal', className)}
      onRequestClose={handleRequestClose}>
      {isAndroid ? (
        <StaticImage
          src="./images/phones---android@2x.jpg"
          alt="Bold app for Android"
          layout="fullWidth"
          placeholder="blurred"
          width={360}
        />
      ) : (
        <StaticImage
          src="./images/phones---ios@2x.jpg"
          alt="Bold app for iOS"
          layout="fullWidth"
          placeholder="blurred"
          width={360}
        />
      )}
      <ModalBox align="left">
        <Text element="p">
          Use Bold on the {isAndroid ? 'Android' : 'iOS'} mobile app. Take classes anytime,
          anywhere.
        </Text>
        <Button full onClick={handleDownloadClick}>
          Download now
        </Button>
      </ModalBox>
    </Modal>
  )
}
