import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
import HeaderSubheaderTitle from 'components/HeaderSubheaderTitle'
import type { Component } from 'constants/types'
import styles from './HeaderSubheader.module.scss'

type Props = {
  center?: boolean
  children?: React.ReactNode
  shadow?: boolean
  title?: string
} & Component

export default function HeaderSubheader({
  center,
  children,
  className,
  shadow = true,
  title,
}: Props) {
  return (
    <header
      className={classNames(
        'HeaderSubheader',
        styles.this,
        shadow && styles['this---shadow'],
        className
      )}>
      <Container size="xxlarge" className={center && styles.container}>
        {title && <HeaderSubheaderTitle>{title}</HeaderSubheaderTitle>}
        {children}
      </Container>
    </header>
  )
}
