import type { ExerciseProgram } from 'libs/program'

export const CLASS_TOO_EASY = 'easy'
export const CLASS_TOO_HARD = 'hard'
export const SITTING_DIFFICULTY_RESPONSE = '1'
export const STANDING_DIFFICULTY_RESPONSE = '2'

export function getProgramTitle(program: ExerciseProgram) {
  if (!program) return null
  const databaseTitle = program.external_name || program.externalName || program.title
  return `${databaseTitle} Program`
}
