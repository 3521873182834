import classNames from 'classnames'
import React from 'react'
import * as rdd from 'react-device-detect'
import { v4 as uuidv4 } from 'uuid'
import Video2, { type Video2Props } from 'components/Video2'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import type { Lesson } from 'libs/lesson'
import * as lessonApiRequest from 'libs/lesson-api-request'
import * as logging from 'libs/logging'
import * as urlLib from 'libs/url'
import styles from './LessonVideo.module.scss'

const MAX_PLAY_INTERVAL = 60 // seconds before we save data

export type Props = {
  lesson: Lesson
  onEnd?: Function
  // eslint-disable-next-line no-unused-vars
  onPlay?: () => void
  onProgress?: () => void
  // eslint-disable-next-line no-unused-vars
  onSessionIdUpdate?: (sessionId: string) => void
  playSkippedLesson?: () => void
  thumbnail?: string
  url?: string
  userExerciseProgramId?: string
} & Video2Props

export default function LessonVideo({
  className,
  lesson,
  onEnd,
  onPlay,
  onProgress,
  onSessionIdUpdate,
  playSkippedLesson,
  thumbnail,
  url,
  userExerciseProgramId,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [playTimeElapsed, setPlayTimeElapsed] = React.useState<number>(0)
  const [playTimeStart, setPlayTimeStart] = React.useState<number>(0)
  const [previousPlayedSeconds, setPreviousPlayedSeconds] = React.useState<number>(-1)
  const [sessionId, setSessionId] = React.useState(null)
  const [progressMadeSinceLastUpdate, setProgressMadeSinceLastUpdate] = React.useState(false)

  // TODO: support movement?
  const VIDEO_LENGTH_SEC = (lesson?.duration || 0) * 60
  const { source } = urlLib.getQueryString()

  const lessonEventObject = {
    classType1: lesson.classType?.[0],
    classType2: lesson.classType?.[1],
    isLiveLesson: false,
    isProgramLesson: !!userExerciseProgramId,
    lessonId: lesson.id,
    lessonName: lesson.videoTitle,
    lessonType: lesson.type,
    lessonUrl: url,
    source,
    trainerName: lesson.instructorName,
  }

  const updateLessonStats = async (currentTimestamp: any = null) => {
    logging.toDatadog(`updateLessonStats for user: ${globalContext?.user?.id}`, {
      lessonId: lesson?.id,
      userExerciseProgramId,
    })

    if (lesson) {
      const percentageWatched = ((100 * playTimeElapsed) / VIDEO_LENGTH_SEC).toFixed(2)
      const updateData = {
        duration: playTimeElapsed,
        percentageWatched,
        currentTimestamp: Math.round(currentTimestamp),
        sessionId,
      }
      const result = await lessonApiRequest.updateLesson(updateData, {
        lessonId: lesson.id,
        userExerciseProgramId,
      })

      logging.toDatadog(`updateLessonStats call complete for user: ${globalContext?.user?.id}`, {
        lessonId: lesson.id,
        userExerciseProgramId,
      })

      return result?.data
    }
    return null
  }

  const handlePlaybackDataTracking = async (currentTimestamp: any = null) => {
    if (progressMadeSinceLastUpdate) {
      setProgressMadeSinceLastUpdate(false) // This state change prevents multiple API calls happening simultaneously with no changes in lesson activity

      // leave per sophia
      // eslint-disable-next-line no-console
      console.log('MAKE API CALL')

      updateLessonStats(currentTimestamp)
        .then((response) => {
          if (!globalContext.isLessonCompleted && response?.status === 'completed') {
            globalContext.update({ isLessonCompleted: true })
          }
          // Load the latest user data for future tracking
          // if the lesson was already skipped, it already has a lessonStat and lessonView - so no need to create a userStat/userView on play again
          if (lesson.userView?.status === 'skipped') {
            playSkippedLesson()
          }
          globalContext.updateUser()
        })
        .catch((error) => {
          setProgressMadeSinceLastUpdate(true) // if the API fails, next call to this function should trigger a new API call
          logging.toDatadog(`Failed to update lesson stats for user: ${globalContext?.user?.id}`, {
            error,
            lessonId: lesson?.id,
            userExerciseProgramId,
          })
        })
    }
  }

  const handleExitPlayer = React.useCallback(async () => {
    const exitTime = playTimeStart + playTimeElapsed
    handlePlaybackDataTracking(exitTime).then(() => {
      globalContext.analytics?.trackEvent(events.EXIT_LESSON, {
        ...lessonEventObject,
        exitTime,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const newSessionId = uuidv4() // Generate a new session ID
    setSessionId(newSessionId)
    if (onSessionIdUpdate) onSessionIdUpdate(newSessionId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(
    () => () => {
      handleExitPlayer().then(() => {
        setSessionId(null)
        globalContext.update({
          isVideoPlaying: false,
          isLessonCompleted: false,
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handlePlay = () => {
    handlePlaybackDataTracking(playTimeStart).then(() => {
      if (onPlay) onPlay()
      if (playTimeStart !== 0) {
        globalContext.analytics?.trackEvent(events.RESUME_LESSON, lessonEventObject)
      } else {
        globalContext.update({ isVideoPlaying: true })
      }
    })
  }

  const resetPlayTime = (resetTime: number) => {
    setPlayTimeStart(resetTime)
    setPlayTimeElapsed(0)
  }

  const handleProgress = (reactPlayerProgressProps: any) => {
    const playedSeconds =
      reactPlayerProgressProps && Math.ceil(reactPlayerProgressProps.playedSeconds)
    const elapsedTime = playedSeconds - playTimeStart

    if (onProgress) onProgress(playedSeconds)

    if (playedSeconds === previousPlayedSeconds || playedSeconds < playTimeStart) return // no change but handleProgress was triggered, or seeking
    setPreviousPlayedSeconds(playedSeconds)

    if (elapsedTime >= MAX_PLAY_INTERVAL) {
      logging.toDatadog(`Video handleProgress for user: ${globalContext?.user?.id}`, {
        playedSeconds,
        classesWatched: globalContext.user?.allTimeClassesWatched,
      })

      handlePlaybackDataTracking(playedSeconds).then(() => resetPlayTime(playedSeconds))
    } else {
      setPlayTimeElapsed(elapsedTime)
      setProgressMadeSinceLastUpdate(true)
    }
  }

  const handlePause = () => {
    const pauseTime = playTimeStart + playTimeElapsed
    handlePlaybackDataTracking(pauseTime).then(() => {
      const complete = pauseTime >= VIDEO_LENGTH_SEC
      if (!complete) {
        globalContext.analytics?.trackEvent(events.PAUSE_LESSON, {
          ...lessonEventObject,
          pauseTime,
        })
      }
      resetPlayTime(pauseTime)
    })
  }

  const handleEnded = async () => {
    const endTime = playTimeStart + playTimeElapsed
    await handlePlaybackDataTracking(endTime)
    globalContext.analytics?.trackEvent(events.COMPLETE_LESSON, lessonEventObject)
    resetPlayTime(endTime)
    if (onEnd) {
      await onEnd()
    }
  }

  const handleSeek = (seekTime: number) => {
    handlePlaybackDataTracking(seekTime).then(() => {
      const pauseTime = playTimeStart + playTimeElapsed
      const complete = pauseTime >= VIDEO_LENGTH_SEC
      if (!complete) {
        globalContext.analytics?.trackEvent(events.PAUSE_LESSON, {
          ...lessonEventObject,
          pauseTime,
        })
      }
      resetPlayTime(Math.ceil(seekTime))
    })
  }

  let customStartTimestamp = lesson.userView?.latestTimestamp
  const lessonDurationSeconds = lesson.duration ? lesson.duration * 60 : 0
  // if timestamp is at the end of the video, just set to null
  if (customStartTimestamp + 30 > lessonDurationSeconds) {
    customStartTimestamp = 0
  }

  return (
    <Video2
      buttonText="Start class"
      playing={!rdd.isMobile}
      showPlayIcon
      customStartTimestamp={customStartTimestamp}
      {...props}
      playsinline
      thumbnail={thumbnail || lesson.videoThumbnail}
      url={url || lesson.videoLink}
      data-test-id="video-player"
      className={classNames('LessonVideo', styles.this, className)}
      onEnded={handleEnded}
      onPause={handlePause}
      onPlay={handlePlay}
      onProgress={handleProgress}
      onSeek={handleSeek}
    />
  )
}
