import { Form, Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Divider from 'components/Divider'
import Field2 from 'components/Field2'
import Heading from 'components/Heading'
import View from 'components/View'
import AdminLayout from 'features/admin/components/AdminLayout'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import AdminLessonRegimenStreams from './components/AdminLessonRegimenStreams'

export default function LessonRegimens() {
  const [lessonRegimens, setLessonRegimens] = React.useState([])
  const [lessonRegimenId, setLessonRegimenId] = React.useState(null)

  async function getRegimensList() {
    const response = await adminApiRequest.getLessonRegimens()
    if (response && response.statusCode === 200) {
      setLessonRegimens(response.data)
    }
  }

  React.useEffect(() => {
    getRegimensList()
  }, [])

  function handleUpdate() {
    getRegimensList()
  }

  function handleSubmit(values: any, formikActions) {
    setLessonRegimenId(values.lessonRegimenId)
    formikActions.setSubmitting(false)
  }

  const regimenOptions = lessonRegimens.map((regimen) => ({
    label: `${regimen.title} - ${regimen.instructorName}`,
    title: regimen.title,
    value: regimen.id,
  }))
  const sortedRegimenOptions = _.sortBy(regimenOptions, (regimen) => regimen.title)

  return (
    <AdminLayout className="AdminLessonRegimens">
      <View>
        <Heading level={1}>Recurring live classes</Heading>
        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {(formikProps) => (
            <Form>
              <Field2
                label="What series do you want to look into?"
                name="lessonRegimenId"
                type="select"
                options={sortedRegimenOptions}
              />
              <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
                Select
              </Button>
            </Form>
          )}
        </Formik>
        <Divider />
        {lessonRegimenId && (
          <AdminLessonRegimenStreams lessonRegimenId={lessonRegimenId} onUpdate={handleUpdate} />
        )}
      </View>
    </AdminLayout>
  )
}
