import { useParams } from '@reach/router'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Fieldset from 'components/Fieldset'
import Input from 'components/Input'
import Label from 'components/Label'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import View from 'components/View'
import AdminLayout from 'features/admin/components/AdminLayout'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import AdminUsersDelete from './components/AdminUsersDelete'
import AdminUsersGoalAndRemindersForm from './components/AdminUsersGoalAndRemindersForm'
import AdminUsersLinks from './components/AdminUsersLinks'
import AdminUsersList from './components/AdminUsersList'
import AdminUsersPause from './components/AdminUsersPause'
import AdminUsersProfileForm from './components/AdminUsersProfileForm'
import AdminUsersProgramChange from './components/AdminUsersProgramChange'

export default function AdminUsers() {
  const { id } = useParams()
  const [loading, setLoading] = React.useState(false)
  const [userId, setUserId] = React.useState<string | null>(null)
  const [users, setUsers] = React.useState([])

  React.useEffect(() => {
    if (id) setUserId(id)
  }, [id])

  async function getUser() {
    if (!userId || userId.length < 36) return
    setLoading(true)

    const userResponse = await adminApiRequest.getUser(userId)

    setUsers(userResponse?.data)
    setLoading(false)
  }

  React.useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  function handleInputChange(event) {
    setUserId(event.target.value)
  }

  function setUser() {
    navigate(`/app/admin/users/${userId}`)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <AdminLayout className="AdminUsers">
      <View>
        <Row>
          <Container align="left" flush size="small">
            <Label>Search by Bold UID</Label>
            <MediaObject
              figure={<Button onClick={getUser}>Search users</Button>}
              figurePosition="right"
              size="small">
              <Input type="text" onChange={handleInputChange} />
            </MediaObject>
          </Container>
        </Row>
        {!_.isEmpty(users) ? (
          <Container size="large">
            <Fieldset title="Profile">
              <AdminUsersProfileForm userId={userId} />
            </Fieldset>
            <Fieldset title="Program">
              <AdminUsersProgramChange userId={userId} />
            </Fieldset>
            <Fieldset title="Goal and reminders">
              <AdminUsersGoalAndRemindersForm userId={userId} />
            </Fieldset>
            <Fieldset title="Pause">
              <AdminUsersPause userId={userId} />
            </Fieldset>
            <Fieldset title="Third Party Links">
              <AdminUsersLinks userId={userId} />
            </Fieldset>
            <Fieldset title="Deletion">
              <AdminUsersDelete userId={userId} />
            </Fieldset>
          </Container>
        ) : (
          <AdminUsersList users={users} setUser={setUser} />
        )}
      </View>
    </AdminLayout>
  )
}
