import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import * as navigation from 'libs/navigation'
import styles from './HeaderBack.module.scss'

type Props = {
  children?: React.ReactNode
  flush?: boolean
  onClick?: () => void
  to?: string
} & Component

export default function HeaderBack({ children = 'Back', className, flush, onClick, to }: Props) {
  const location = useLocation()

  function handleClick() {
    if (onClick) onClick()

    const destination = to ?? location?.state?.from
    console.log('destination:', destination)
    if (destination) {
      navigate(destination, { state: location?.state })
    } else {
      console.log('window?.history?.length', window?.history?.length)
      if (window?.history?.length > 1) {
        navigation.back() // go back if there's history
      } else {
        navigate(paths.HOME_AUTHED) // fallback route
      }
    }
  }

  return (
    <header
      className={classNames('HeaderBack', styles.this, flush && styles['this---flush'], className)}>
      <Container flush={flush} full>
        <nav className={styles.nav}>
          <Button color="white" icon="arrow-left" onClick={handleClick}>
            {children}
          </Button>
        </nav>
      </Container>
    </header>
  )
}
