import { Form, Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import * as Yup from 'yup'
import Box from 'components/Box'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Field2 from 'components/Field2'
import FieldCheckboxButtons from 'components/FieldCheckboxButtons'
import FieldPhoneNumber from 'components/FieldPhoneNumber'
import FieldTime from 'components/FieldTime'
import FormValidationNotification from 'components/FormValidationNotification'
import Label from 'components/Label'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import copy from 'constants/copy'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as date from 'libs/date'
import * as notifications from 'libs/notifications'
import * as userApiRequest from 'libs/user-api-request'
import { useUserGoal } from 'libs/user-query'
import * as validations from 'libs/validations'
import constants from 'styles/constants.module.scss'
import { ReactComponent as BellSvg } from './images/bell.svg'
import { ReactComponent as ClockArrowsSvg } from './images/clock-arrows.svg'
import { ReactComponent as SunSvg } from './images/sun.svg'

type RemindersFormBoxProps = {
  children: React.ReactNode
}

function RemindersFormBox(props: RemindersFormBoxProps) {
  return (
    <Row size="xxsmall">
      <Box {...props} color="grey" size="small" />
    </Row>
  )
}

type Props = {
  goalUpdated?: number
  onBack?: () => void
  // eslint-disable-next-line no-unused-vars
  onSuccess: (reminderDays?: any, successful?: boolean) => void
}

export default function RemindersForm({ goalUpdated, onBack, onSuccess }: Props) {
  const globalContext = useGlobalContext()
  const { data: userGoalData, isLoading: userGoalLoading, mutate: mutateUserGoal } = useUserGoal()
  const { width } = useWindowSize()

  async function submit(values, formikActions) {
    // also updates phone number & SMS consent
    const response = await userApiRequest.updateUserGoal({
      ...values,
      source: 'User set goal & reminders',
    })

    let successful = false
    if (response.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Updating goal & reminders did not work. Please try again later.')
    } else {
      successful = true
      mutateUserGoal() // to reflect goal/reminder change
      await globalContext.updateUser() // to reflect phone number change
      notifications.notifySuccess(
        values.reminderDays[0] !== 'None'
          ? 'Goal and reminders set!'
          : 'Goal and reminders removed!'
      )
    }

    if (formikActions) formikActions.setSubmitting(false)
    onSuccess(values.reminderDays, successful)
  }

  function handleSubmit(values, formikActions) {
    if (!_.isEmpty(values.reminderDays) && _.includes(values.reminderDays, 'None')) {
      _.remove(values.reminderDays, (value) => value === 'None')
    }

    if (_.isEmpty(values.reminderDays)) values.reminderDays = ['None']

    values.goalExerciseFrequency = Number.parseInt(values.goalExerciseFrequency, 10)

    if (
      !values.reminderEmailConsent &&
      !values.reminderSmsConsent &&
      !!values.goalExerciseFrequency
    ) {
      notifications.notifyError('Must select at least one way to be reminded')
      formikActions.setSubmitting(false)
      return
    }

    return submit(values, formikActions)
  }

  function handleCancelButtonClick() {
    handleSubmit(
      {
        reminderDays: [],
        reminderTimeExact: null,
      },
      null
    )
  }

  const reminderDays = _.get(userGoalData, 'data.reminderDays', [])
  const reminderTimeExact = _.get(userGoalData, 'data.reminderTimeExact', null)
  const hasReminders =
    (!_.isEmpty(reminderDays) && reminderDays[0] !== 'None') || !!reminderTimeExact
  const reminderEmailConsent = _.get(userGoalData, 'data.reminderEmailConsent', true)
  const reminderSmsConsent =
    _.get(userGoalData, 'data.reminderSmsConsent', false) ||
    (!hasReminders && globalContext.user?.phoneNumber !== '')

  const fieldProps = {
    align: 'left',
    flush: true,
    size: 'small',
  }

  const validationSchema = Yup.object().shape({
    goalExerciseFrequency: validations.REQUIRED,
    phoneNumber: Yup.string().test(
      'is-required-when-consent-given',
      'A valid phone number is required if SMS reminders are enabled',
      (value, context) => {
        const { reminderSmsConsent: smsConsentValue } = context.parent
        if (smsConsentValue) {
          return validations.PHONE_NUMBER.isValidSync(value)
        }
        return true // No validation if reminderSmsConsent is false
      }
    ),
    reminderDays: validations.REMINDERS_DAYS,
    reminderTimeExact: validations.REQUIRED,
  })

  if (userGoalLoading) {
    return <Loader overlay={false} />
  }

  return (
    <Formik
      initialValues={{
        goalExerciseFrequency: goalUpdated ?? userGoalData?.data?.goalExerciseFrequency?.toString(),
        phoneNumber: globalContext.user.phoneNumber ?? '',
        reminderDays,
        reminderEmailConsent,
        reminderSmsConsent,
        reminderTimeExact: reminderTimeExact || '09:00',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FormValidationNotification />
          <Row size="small">
            <RemindersFormBox>
              <FieldCheckboxButtons
                {...fieldProps}
                label={
                  <TextWithIcon icon={<ClockArrowsSvg />}>
                    Which days would you like us to send a reminder?
                  </TextWithIcon>
                }
                name="reminderDays"
                axis="x"
                options={date.DAYS_OF_WEEK.map((item: string, index: number) => ({
                  text: width <= constants.MOBILE_PX ? date.DAYS_OF_WEEK_SMALL[index] : item,
                  value: item,
                }))}
              />
            </RemindersFormBox>
            <RemindersFormBox>
              <FieldTime
                {...fieldProps}
                label={
                  <TextWithIcon icon={<SunSvg />}>
                    What time would you like to be reminded?
                  </TextWithIcon>
                }
                name="reminderTimeExact"
              />
            </RemindersFormBox>
            <RemindersFormBox>
              <Label>
                <TextWithIcon icon={<BellSvg />}>How would you like to be reminded?</TextWithIcon>
              </Label>
              <Row size="xxsmall">
                <Field2 label="Email" flush name="reminderEmailConsent" type="checkbox" />
              </Row>
              <Row size="xxsmall">
                <Field2 label="Text message" flush name="reminderSmsConsent" type="checkbox" />
              </Row>
              {formikProps.values.reminderSmsConsent && (
                <FieldPhoneNumber flush labelHidden>
                  <Text align="left" color="ink200" element="p" flush size="xsmall">
                    {copy.PHONE_NUMBER_DISCLAIMER}
                  </Text>
                </FieldPhoneNumber>
              )}
            </RemindersFormBox>
          </Row>
          <Row flush={!hasReminders} size="small">
            <MediaObject
              figure={
                onBack && (
                  <Button color="white" icon="arrow-left" onClick={onBack}>
                    Back
                  </Button>
                )
              }
              size="xsmall">
              <Button disabled={formikProps.isSubmitting} full onClick={formikProps.submitForm}>
                Save
              </Button>
            </MediaObject>
          </Row>
          {hasReminders && (
            <Buttons align="center">
              <Button level="text" onClick={handleCancelButtonClick}>
                Never mind, cancel all reminders
              </Button>
            </Buttons>
          )}
        </Form>
      )}
    </Formik>
  )
}
