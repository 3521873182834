export type ProgramBlock = {
  description: string
  exercisePrograms: {
    programExerciseBlock: {
      indexInProgram: number
    }
  }
  id: string
  title: string
}

export type ExerciseProgram = {
  alternateProgramId: string
  createdAt: string
  description: string
  exerciseBlocks: ProgramBlock[]
  external_name?: string
  externalName?: string
  id: string
  ifTooEasy: string
  ifTooHard: string
  isActive: true
  level: null
  membersPlacedInProgram: null
  notes: null
  numClasses: number | null
  numWeeks: number | null
  similarProgramId: null
  tags: null
  title: string
  updatedAt: string
}

export type Program = {
  currentBlockExpectedEndAt: string
  currentBlockIndex: 0
  currentBlockStartAt: string
  exerciseProgram: ExerciseProgram
}

export type ProgramDynamic = {
  id: string
  status: string
  programType: string
  isActive: boolean
}

export type ProgramDynamicBlock = {
  id: string
  userId: string
  userExerciseProgramId: string
  status: string
  indexInProgram: number
  // TODO: implement?
  numLessons: number
  numLessonsCompleted: number
  numLessonsSkipped: number
  // TODO: include others too?
}

export const TYPE_STATIC = 'static'
export const TYPE_DYNAMIC = 'dynamic'
