import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Text from 'components/Text'
import styles from './AdminUsersList.module.scss'

type Props = {
  users: any
  setUser: () => void
}

export default function AdminUsersList({ users, setUser }: Props) {
  if (_.isNil(users) || _.isEmpty(users)) return <div>No results—please enter a search term</div>

  return (
    <Text element="table" size="small" className={classNames('AdminUsersList', styles.this)}>
      <tr>
        <th>Email</th>
        <th />
      </tr>
      {users.map((user) => (
        <tr className={styles.row}>
          <td>{user.email}</td>
          <td>
            <Button color="white" size="small" onClick={() => setUser(user)}>
              Select
            </Button>
          </td>
        </tr>
      ))}
    </Text>
  )
}
