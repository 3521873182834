import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Text from 'components/Text'
import Title from 'components/Title'
import View from 'components/View'
import paths from 'constants/paths'
import SubscriptionUpgradeSection from 'features/user/components/SubscriptionUpgradeSection'
import * as exploreApiRequest from 'libs/explore-api-request'
import LessonsBasic from './components/LessonsBasic'

export default function ProgramBasic() {
  const [isUnlockedClassesLoading, setIsUnlockedClassesLoading] = React.useState(true)
  const [lockedClasses, setLockedClasses] = React.useState()
  const [unlockedClasses, setUnlockedClasses] = React.useState()

  async function getLockedClasses() {
    const lockedClassesResponse = await exploreApiRequest.getLessons({})
    setLockedClasses(lockedClassesResponse.data?.slice(0, 9))
  }

  async function getUnlockedClasses() {
    const basicClassesResponse = await exploreApiRequest.getLessons({
      isBoldBasicClass: 'true',
    })
    setUnlockedClasses(basicClassesResponse.data)
    setIsUnlockedClassesLoading(false)
  }

  React.useEffect(() => {
    getLockedClasses()
    getUnlockedClasses()
  }, [])

  return (
    <View className="ProgramBasic">
      <Container flush size="xlarge">
        <LessonsBasic data={unlockedClasses} loading={isUnlockedClassesLoading} />
        <Box size="large">
          <Text align="center" element="div">
            <SubscriptionUpgradeSection />
          </Text>
        </Box>
        <LessonsBasic data={lockedClasses} locked />
        <Container size="medium">
          <Text align="center" element="section">
            <Title
              actions={
                <Button to={paths.SETTINGS_SUBSCRIPTION_CHANGE} size="xlarge">
                  Upgrade
                </Button>
              }>
              Upgrade for access to more Bold classes and experiences
            </Title>
          </Text>
        </Container>
      </Container>
    </View>
  )
}
