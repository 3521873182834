import { navigate } from 'gatsby'
import React from 'react'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import ProgramBuild from 'features/program/components/ProgramBuild'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as enterprise from 'libs/enterprise'

export default function OnboardingProgramBuild() {
  useEventPageVisited('Onboarding program build')
  const globalContext = useGlobalContext()

  return (
    <ProgramBuild
      className="OnboardingProgramBuild"
      onComplete={() =>
        navigate(
          !enterprise.isClinical(globalContext.partner)
            ? paths.ONBOARDING_COMMITMENT
            : paths.HOME_AUTHED,
          { replace: true }
        )
      }
    />
  )
}
