import classNames from 'classnames'
import React from 'react'
import Buttons from 'components/Buttons'
import HeaderSubheader from 'components/HeaderSubheader'
import Layout from 'components/Layout'
import Link from 'components/Link'
import View from 'components/View'
import paths from 'constants/paths'
import type { Component } from 'constants/types'

type Props = {
  children: React.ReactNode
  title: string
} & Component

export default function ActivityLayout({ className, title, ...props }: Props) {
  const navs = [
    {
      copy: 'Activity',
      to: paths.ACTIVITY,
      hide: title === 'Activity',
    },
    {
      copy: 'Badges',
      to: paths.BADGES,
      hide: title === 'Badges',
    },
  ]

  return (
    <Layout
      headerProps={{
        subheader: (
          <HeaderSubheader center title={title}>
            <Buttons align="right" size="large">
              {navs
                .filter((item) => !item.hide)
                .map((item) => (
                  <Link to={item.to} color="grey" key={item.copy} nav>
                    {item.copy}
                  </Link>
                ))}
            </Buttons>
          </HeaderSubheader>
        ),
      }}
      className={classNames('ActivityLayout', className)}>
      <View {...props} />
    </Layout>
  )
}
