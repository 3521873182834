export const CHALLENGE_TYPE = {
  DAILY: 'daily',
  ONEOFF: 'oneoff',
  PROGRAM: 'program',
  WEEKLY: 'weekly',
}

export type Challenge = {
  collectionId: string
  description: string
  disclaimer?: string
  id: string
  isActive: boolean
  name: string
  newProgramId: string
  tag: string
  threshold: number
  type: (typeof CHALLENGE_TYPE)[keyof typeof CHALLENGE_TYPE]
  unit: string
}

export const CHALLENGE_STATUS = {
  ACTIVE: 'active',
  COMPLETE: 'complete',
  EXPIRED: 'expired',
}

export const CHALLENGE_TAG: { [key: string]: string } = {
  BALANCE: 'Balance',
  LEARN: 'learn',
  LIVE_CLASS_SIGN_UP: 'live_class_signup',
  MOVE: 'move',
  READ_BLOG_POST: 'read_blog_post',
  SKILL_BUILDING: 'skill_building',
  WATCH_LESSON: 'watch_lesson',
}

export type UserChallenge = {
  challenge_id?: string
  challenge: Challenge
  challengeId: string
  complete: boolean
  createdAt?: string
  id: string
  isChallengeActive: boolean
  lessonId?: string
  refreshCount?: number
  progress: number
  status: string
  tag: string
  type: string
}
