import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Rows from 'components/Rows'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import Challenge from 'features/user/components/Challenge'
import SubscriptionUpgradeSection from 'features/user/components/SubscriptionUpgradeSection'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as lessonApiRequest from 'libs/lesson-api-request'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import styles from './Explore.module.scss'
import ExploreBlogPosts from './components/ExploreBlogPosts'
import ExploreLessonsBoldTalks from './components/ExploreLessonsBoldTalks'
import ExploreLessonsFavorite from './components/ExploreLessonsFavorite'
import ExploreLessonsNew from './components/ExploreLessonsNew'
import ExploreLessonsPlaylist from './components/ExploreLessonsPlaylist'
import ExploreLessonsRecommended from './components/ExploreLessonsRecommended'
import ExploreLessonsTrainer from './components/ExploreLessonsTrainer'
import ExploreLiveLessons from './components/ExploreLiveLessons'
import ExplorePlaylists from './components/ExplorePlaylists'
import { ReactComponent as BalanceSvg } from './images/categories/balance.svg'
import { ReactComponent as CardioSvg } from './images/categories/cardio.svg'
import { ReactComponent as DanceSvg } from './images/categories/dance.svg'
import { ReactComponent as EducationSvg } from './images/categories/education.svg'
import { ReactComponent as FlexibilitySvg } from './images/categories/flexibility.svg'
import { ReactComponent as MeditationSvg } from './images/categories/meditation.svg'
import { ReactComponent as PilatesSvg } from './images/categories/pilates.svg'
import { ReactComponent as StrengthSvg } from './images/categories/strength.svg'
import { ReactComponent as TaiChiSvg } from './images/categories/tai-chi.svg'
import { ReactComponent as YogaSvg } from './images/categories/yoga.svg'

export default function Explore() {
  useEventPageVisited('Explore landing')
  const globalContext = useGlobalContext()
  const [clinicalPlaylists, setClinicalPlaylists] = React.useState([])
  const [hasTakenClassInLastWeek, setHasTakenClassInLastWeek] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)

  const isClinical = user.isEnterpriseClinicalUser(globalContext.user)

  // determine where to put education carousels
  React.useEffect(() => {
    async function getData() {
      const response = await userApiRequest.getRecentLessons() // fetches last 15 lessons they started or completed

      if (response.statusCode === 200 && response?.data && !_.isEmpty(response.data)) {
        // see if user completed any lesson within last 7 days
        const completedLessons = response.data.filter((lesson) => lesson.lessonCompleted === 'true')
        if (completedLessons.length > 0) {
          const latestLessonStartTs = new Date(completedLessons[0].lessonStartTs)
          const oneWeekAgo = new Date()
          oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
          setHasTakenClassInLastWeek(latestLessonStartTs >= oneWeekAgo)
        }
      }

      setIsLoading(false)
    }
    getData()
  }, [])

  // get playlists for clinical users
  React.useEffect(() => {
    async function getPlaylists() {
      if (!isClinical) return
      const response = await lessonApiRequest.getPlaylists()

      if (response.statusCode !== 200) {
        console.error(
          `Explore: error fetching clinical playlists for user ${globalContext.user?.id}`
        )
        return
      }

      setClinicalPlaylists(response.data)
    }
    getPlaylists()
  }, [globalContext.user, isClinical])

  const categories = [
    {
      copy: 'Balance',
      icon: BalanceSvg,
    },
    {
      copy: 'Cardio',
      icon: CardioSvg,
    },
    {
      copy: 'Dance',
      icon: DanceSvg,
    },
    {
      copy: 'Education',
      icon: EducationSvg,
    },
    {
      copy: 'Flexibility',
      icon: FlexibilitySvg,
    },
    {
      copy: 'Strength',
      icon: StrengthSvg,
    },
    {
      copy: 'Meditation',
      icon: MeditationSvg,
    },
    {
      copy: 'Pilates',
      icon: PilatesSvg,
    },
    {
      copy: 'Tai Chi',
      icon: TaiChiSvg,
    },
    {
      copy: 'Yoga',
      icon: YogaSvg,
    },
  ]

  const isFreeUser = user.isFreeUser(globalContext.user)

  const boldTalksAndBlogPosts = (
    <>
      <ExploreLessonsBoldTalks />
      <ExploreBlogPosts />
    </>
  )

  const sections = isClinical
    ? [
        <ExploreLessonsRecommended />,
        !isLoading && !hasTakenClassInLastWeek && boldTalksAndBlogPosts,
        ...clinicalPlaylists.map((playlist) => (
          <ExploreLessonsPlaylist key={playlist.id} id={playlist.id} />
        )),
        <ExploreLessonsFavorite />,
        !isLoading && hasTakenClassInLastWeek && boldTalksAndBlogPosts,
      ]
    : [
        <ExploreLessonsRecommended />,
        <ExploreLessonsNew />,
        <Challenge badge color="pink" from={paths.EXPLORE} />,
        !isLoading && !hasTakenClassInLastWeek && boldTalksAndBlogPosts,
        <ExploreLiveLessons />,
        <ExploreLessonsTrainer />,
        <ExplorePlaylists />,
        <ExploreLessonsFavorite />,
        !isLoading && hasTakenClassInLastWeek && boldTalksAndBlogPosts,
      ]

  return (
    <Layout className="Explore">
      <Head bodyAttributes={{ class: 'body---grey' }} />
      <View color="grey" footer className={styles.view}>
        <Container flush size="xxlarge">
          <Text align="center" element="h1" weight="bold" className={styles.title}>
            Start your next class
          </Text>
          <div className={isFreeUser && styles.upsell}>
            <div className={isFreeUser && styles['upsell--content']}>
              <div>
                <Row>
                  <Buttons align="center">
                    <Button to={paths.EXPLORE_LESSONS} size="large">
                      Browse all classes
                    </Button>
                  </Buttons>
                </Row>
                {!isClinical && (
                  <div className={styles['category-buttons-wrap']}>
                    <Buttons align="center" wrap={false} className={styles['category-buttons']}>
                      {categories.map((item) => (
                        <Button
                          to={`${paths.EXPLORE_LESSONS}?classType=${item.copy}`}
                          color="white"
                          key={item.copy}
                          className={styles['category-button']}>
                          <div className={styles['category-button--icon']}>
                            <item.icon />
                          </div>
                          <Text element="div" size="small" weight="normal">
                            {item.copy}
                          </Text>
                        </Button>
                      ))}
                    </Buttons>
                  </div>
                )}
                <Rows size="large">{sections.map((item) => item)}</Rows>
              </div>
            </div>
            {isFreeUser && (
              <Container size="medium">
                <Box align="center" color="white" className={styles['upsell--box']}>
                  <SubscriptionUpgradeSection />
                </Box>
              </Container>
            )}
          </div>
        </Container>
      </View>
    </Layout>
  )
}
