import classNames from 'classnames'
import React from 'react'
import Box from 'components/Box'
import Heading from 'components/Heading'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Fieldset.module.scss'

type Props = {
  actions: React.ReactNode
  adornment?: React.ReactNode
  children: React.ReactNode
  footer?: React.ReactNode
  subtext?: string
  title: string
} & Component

export default function Fieldset({
  actions,
  adornment,
  children,
  className,
  footer,
  subtext,
  title,
  ...props
}: Props) {
  return (
    <div {...props} className={classNames('Fieldset', styles.this, className)}>
      <Row size="small">
        <MediaObject
          figure={actions}
          figurePosition="right"
          reset="mobile"
          className={styles.header}>
          <Text element="legend">
            <Heading flush={!subtext} levelStyle={4}>
              {title}
            </Heading>
            {subtext && (
              <Text element="p" flush>
                {subtext}
              </Text>
            )}
          </Text>
        </MediaObject>
      </Row>
      <Row flush={!footer} size="small">
        <Box adornment={adornment} color="white">
          {children}
        </Box>
      </Row>
      {footer && <footer className={styles.footer}>{footer}</footer>}
    </div>
  )
}
