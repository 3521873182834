import classNames from 'classnames'
import React from 'react'
import Text from 'components/Text'
import type { Component } from 'constants/types'

type Props = {
  children: React.ReactNode
} & Component

export default function HeaderSubheaderTitle({ className, ...props }: Props) {
  return (
    <Text
      {...props}
      element="h1"
      size="xlarge"
      weight="bold"
      className={classNames('HeaderSubheaderTitle', className)}
    />
  )
}
