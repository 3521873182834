import _ from 'lodash'
import React from 'react'
import * as challengeLib from 'libs/challenge'
import type { UserChallenge } from 'libs/challenge'
import type { Lesson } from 'libs/lesson'
import * as lessonApiRequest from 'libs/lesson-api-request'
import DashboardTodayLesson from '../DashboardTodayLesson'

type Props = {
  challenge: UserChallenge
}

export default function DashboardTodayLessonLearn({ challenge }: Props) {
  const [lesson, setLesson] = React.useState<Lesson>()

  React.useEffect(() => {
    async function getLesson() {
      if (challenge?.lessonId) {
        try {
          const response = await lessonApiRequest.getLessonById(challenge?.lessonId)
          if (response && response?.data) {
            setLesson(response.data)
          }
        } catch (error) {
          console.error('Error fetching lesson:', error)
        }
      }
    }
    getLesson()
  }, [challenge])

  if (_.isNil(lesson) || _.isEmpty(lesson) || _.isNil(challenge) || _.isEmpty(challenge)) {
    return null
  }

  return (
    <DashboardTodayLesson
      key={lesson.id}
      challenge={challenge}
      challengeTag={challengeLib.CHALLENGE_TAG.LEARN}
      lesson={lesson}
      className="DashboardTodayLessonLearn"
    />
  )
}
