import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Button, { type ButtonProps } from 'components/Button'
import * as events from 'constants/events'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as lessonLib from 'libs/lesson'
import * as liveLesson from 'libs/live-lesson'
import { useLiveLessons } from 'libs/live-lessons-query'
import * as notifications from 'libs/notifications'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'

type Props = {
  children?: string
  data: {}
  onClick?: () => void
  onScheduleClick?: () => void
} & ButtonProps

export default function LiveLessonSignUpButton2({
  children,
  className,
  data,
  onClick,
  onScheduleClick,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const { mutate: mutateLiveLessons } = useLiveLessons({
    revalidateOnFocus: false,
  })
  const [isScheduled, setIsScheduled] = React.useState(
    data && data['scheduledLessons.status'] === 'scheduled'
  )
  const [lessonStream, setLessonStream] = React.useState(data)

  const lesson = data?.lesson

  React.useEffect(() => {
    setLessonStream({
      ...data,
      scheduledLessonId: _.get(data, 'scheduledLessons.id', null),
      scheduledLessonStatus: _.get(data, 'scheduledLessons.status', null),
    })
  }, [data])

  if (_.isEmpty(lessonStream)) return null

  // todo: add react effect / timeout to listen for date
  const isLiveOrLiveSoon = liveLesson.isLessonLiveOrLiveSoon(
    lessonStream.scheduledStartTs,
    lessonStream.lesson.duration
  )

  function trackSignUp(eventData?: any) {
    // TODO: mv to api
    globalContext.analytics?.trackEvent(events.LIVE_CLASS_SCHEDULED, {
      duration: lesson?.duration,
      lessonId: lesson?.id,
      title: lesson?.videoTitle,
      trainer: lesson?.instructorName,
      ...eventData,
    })
  }

  async function scheduleLesson(event) {
    event.preventDefault()
    event.stopPropagation()

    const response = await userApiRequest.scheduleLesson({
      lessonStreamId: lessonStream.id,
      scheduledAt: lessonStream.scheduledStartTs,
    })

    if (response.statusCode === statusCodes.POST_SUCCESS) {
      setIsScheduled(true)

      mutateLiveLessons()

      // update state instead of re-fetching lesson
      setLessonStream({
        ...lessonStream,
        scheduledLessonId: response.data.scheduledLesson.id,
        scheduledLessonStatus: response.data.scheduledLesson.status,
      })

      notifications.notifySuccess('Live class scheduled')

      trackSignUp({
        // tracks whether we sent the schedule reminder via email or sms
        medium: response.data.medium,
      })

      if (onScheduleClick && !globalContext.user?.phoneNumber) {
        onScheduleClick()
      }
    } else {
      console.error(response.message)
      notifications.notifyError(
        response.statusCode === statusCodes.TOO_MANY_REQUESTS
          ? 'Please wait and try again in a few minutes'
          : 'Sorry, something went wrong'
      )
      globalContext.analytics?.trackEvent(events.LIVE_CLASS_SCHEDULE_FAILED, {
        error: response.message,
        lesson: lesson?.id,
        title: lesson?.videoTitle,
      })
    }
  }

  async function joinRestreamLesson(event: any) {
    event.preventDefault()
    event.stopPropagation()

    const lessonStreamLesson = lessonStream?.lesson
    // TODO: call handleClick
    globalContext.analytics?.trackEvent(events.JOIN_LIVE_CLASS_CLICKED, {
      duration: lessonStreamLesson.duration,
      lessonId: lessonStreamLesson.id,
      medium: lessonLib.MEDIUM_LIVE,
      title: lessonStreamLesson.videoTitle,
      trainer: lessonStreamLesson.instructorName,
    })

    navigate(`${paths.LIVE_RESTREAM_LESSON}${lessonStream.id}`)
  }

  async function joinLesson() {
    if (lessonStream.streamType === 'zoom' && isLiveOrLiveSoon) {
      const response = await userApiRequest.scheduleLesson({
        lessonStreamId: lessonStream.id,
        scheduledAt: lessonStream.scheduledStartTs,
      })

      if (response.statusCode === statusCodes.POST_SUCCESS) {
        trackSignUp()

        notifications.notifyAndRedirect({
          delay: true,
          message: 'Successfully added to live class. Redirecting you now…',
          to: response.data?.scheduledLesson?.zoomJoinUrl,
        })
      } else {
        console.error(response.message)
        notifications.notifyError(
          response.statusCode === statusCodes.TOO_MANY_REQUESTS
            ? 'Please wait and try again in a few minutes'
            : 'Sorry, something went wrong'
        )
        globalContext.analytics?.trackEvent(events.LIVE_CLASS_JOIN_FAILED, {
          error: response.message,
          lesson: lesson?.id,
          title: lesson?.videoTitle,
        })
      }

      return
    }

    window.open(lesson['scheduledLessons.zoomJoinUrl'], '_blank')
  }

  async function cancelLessonScheduled(event) {
    event.preventDefault()
    event.stopPropagation()

    const response = await userApiRequest.cancelScheduledLesson(lessonStream.scheduledLessonId)

    if (response.statusCode === statusCodes.SUCCESS) {
      setIsScheduled(false)

      setLessonStream({
        ...lessonStream,
        scheduledLessonStatus: null,
      })

      mutateLiveLessons()

      notifications.notifySuccess('Live class removed from your schedule')
    } else {
      notifications.notifyError()
      globalContext.analytics?.trackEvent(events.LIVE_CLASS_CANCEL_FAILED, {
        error: response.message,
        lesson: lesson?.id,
        title: lesson?.videoTitle,
      })
    }
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    globalContext.analytics?.trackEvent(events.JOIN_LIVE_CLASS_CLICKED, {
      duration: lesson?.duration,
      lessonId: lesson?.id,
      medium: lessonLib.MEDIUM_LIVE,
      title: lesson?.videoTitle,
      trainer: lesson?.instructorName,
    })

    if (!user.hasLiveLessons(globalContext.user)) {
      navigate(paths.LIVE_LESSONS)
      return
    }

    if (onClick) onClick()

    if (isLiveOrLiveSoon) {
      if (lessonStream.streamType === 'rerun') {
        joinRestreamLesson(event)
      } else {
        joinLesson()
      }

      return
    }

    if (lessonStream.scheduledLessonStatus === 'scheduled' || isScheduled) {
      cancelLessonScheduled(event)
    } else {
      scheduleLesson(event)
    }
  }

  return (
    <Button
      {...props}
      className={classNames('LiveLessonSignUpButton2', className)}
      onClick={handleClick}
    />
  )
}
