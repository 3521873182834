import React from 'react'
import Button from 'components/Button'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import DashboardLessons from '../DashboardLessons'

type Props = {
  data: []
  isLoading: boolean
} & Component

export default function DashboardLessonsFavorite({ data, isLoading = true }: Props) {
  const globalContext = useGlobalContext()

  function handleButtonClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_LESSONS_FAVORITE_VIEW_ALL_CLICK)
  }

  return (
    <DashboardLessons
      title="My saved classes"
      action={
        <Button to={paths.LESSONS_FAVORITE} onClick={handleButtonClick}>
          See all saved classes
        </Button>
      }
      data={data}
      loading={isLoading}
      className="DashboardLessonsFavorite"
    />
  )
}
