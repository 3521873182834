import React from 'react'
import Link from 'components/Link'
import Rows from 'components/Rows'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'

type Props = {
  userId: string
}

export default function AdminUsersLinks({ userId }: Props) {
  const [email, setEmail] = React.useState()

  React.useEffect(() => {
    async function getUser() {
      const response = await adminApiRequest.getUser(userId)
      setEmail(response?.data?.email)
    }
    getUser()
  }, [userId])

  const linkProps = {
    decoration: 'underline',
    target: '_blank',
  }

  return (
    <Rows size="small">
      <Link to={`https://crm.na1.insightly.com/search/?q=${userId}&type=Contact`} {...linkProps}>
        Insightly
      </Link>
      <Link to={`https://app.iterable.com/users/profiles/${email}/fields`} {...linkProps}>
        Iterable
      </Link>
      <Link
        to={`https://app.logrocket.com/vsas4n/age-bold/sessions?filters=%255B%257B%2522type%2522%253A%2522userID%2522%252C%2522operator%2522%253A%257B%2522name%2522%253A%2522is%2522%252C%2522type%2522%253A%2522IS%2522%252C%2522hasStrings%2522%253Atrue%252C%2522autocompleteEnabled%2522%253Atrue%257D%252C%2522strings%2522%253A%255B%2522${userId}%2522%255D%257D%255D&persistForm=true&range=1M`}
        {...linkProps}>
        Logrocket
      </Link>
      <Link
        to={`https://mixpanel.com/project/2330259/view/2874245/app/profile#distinct_id=${userId}`}
        {...linkProps}>
        Mixpanel
      </Link>
    </Rows>
  )
}
