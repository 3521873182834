import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import DateTime from 'components/DateTime'
import HeaderBack from 'components/HeaderBack'
import Heading from 'components/Heading'
import Layout from 'components/Layout'
import Table from 'components/Table'
import View from 'components/View'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as subscriptionApiRequest from 'libs/subscription-api-request'
import { ReactComponent as DownloadSvg } from './images/download.svg'

type Invoice = {
  amount_paid: number
  created: string
  hosted_invoice_url: string
  id: string
  number: string
}

export default function SettingsInvoices() {
  useEventPageVisited('Settings invoices')
  const globalContext = useGlobalContext()
  const [invoices, setInvoices] = React.useState([])

  React.useEffect(() => {
    async function getInvoices() {
      const response = await subscriptionApiRequest.getInvoicesForUser()

      if (response.code !== statusCodes.SUCCESS) return

      setInvoices(response.data.invoices)
    }
    getInvoices()
  }, [globalContext.user?.id])

  return (
    <Layout className="SettingsInvoices">
      <View>
        <HeaderBack flush />
        <Container flush>
          <Heading levelStyle={4}>Invoices</Heading>
          <Box color="white">
            <Table
              actionsColumn
              head={['Date', 'Invoice number', 'Total amount']}
              loading={_.isEmpty(invoices)}>
              {invoices.map((item: Invoice) => (
                <tr key={item.id}>
                  <td>
                    <DateTime data={item.created} />
                  </td>
                  <td>{item.number}</td>
                  <td>${item.amount_paid / 100}</td>
                  <td>
                    <Button
                      to={item.hosted_invoice_url}
                      level="tertiary"
                      size="xsmall"
                      aria-label="Download">
                      <DownloadSvg aria-hidden />
                    </Button>
                  </td>
                </tr>
              ))}
            </Table>
          </Box>
        </Container>
      </View>
    </Layout>
  )
}
