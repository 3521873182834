import React from 'react'
import SubscriptionPaymentMethodForm from 'features/user/components/SubscriptionPaymentMethodForm'
import PromoCode from './components/PromoCode'

type Props = {
  formId: string
  hidePromoCode?: boolean
}

export default function SubscriptionPayment({ formId, hidePromoCode, ...props }: Props) {
  return (
    <div className="SubscriptionPayment">
      <SubscriptionPaymentMethodForm {...props} id={formId} />
      {!hidePromoCode && <PromoCode flush />}
    </div>
  )
}
