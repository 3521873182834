import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Divider from 'components/Divider'
import Heading from 'components/Heading'
import Input from 'components/Input'
import Label from 'components/Label'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import AdminLayout from 'features/admin/components/AdminLayout'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import LessonBox from 'features/lesson/components/LessonBox'
import * as notifications from 'libs/notifications'
import styles from './AdminAnalytics.module.scss'
import AdminAnalyticsGraph from './components/AdminAnalyticsGraph'

type Lesson = {
  id: string
  videoTitle: string
  duration: number
  total_users_watched: number
  avg_duration_per_lesson: number
  avg_percentage_watched: string
}

type RetentionDataPoint = {
  time: number
  users_retained: number
}

type DataType = {
  lessons: Lesson[]
  latestTimestamp: Record<string, RetentionDataPoint[][]>
}

export default function AdminAnalytics() {
  const [lessonIds, setLessonIds] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<DataType | null>(null)

  async function getLesson() {
    if (!lessonIds || lessonIds.length === 0) {
      notifications.notifyError('Oops! You forgot to add lesson IDs 😩')
      return
    }

    setLoading(true)

    try {
      const response = await adminApiRequest.bulkGetLessonInfoForAnalytics(lessonIds)
      const { lessons, latestTimestamp } = response?.data || {}

      if (_.isEmpty(lessons) && _.isEmpty(latestTimestamp)) {
        notifications.notifyError('No lessons found. Try different IDs! 🤔')
        return
      }

      setData(response.data)
    } catch (error) {
      notifications.notifyError('Something went wrong. Please try again! 😩')
      console.error('Error fetching lessons:', error)
    } finally {
      setLoading(false)
    }
  }

  function handleInputChange(event) {
    setLessonIds(event.target.value)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <AdminLayout className="AdminAnalytics">
      <View className={styles.this}>
        <Heading level={1}>Analytics</Heading>
        <Row>
          <Label>Search by Lesson ID (Comma separated, no spaces, 6 lesson IDs max.)</Label>
          <MediaObject
            figure={<Button onClick={getLesson}>Search Lesson</Button>}
            figurePosition="right"
            size="small">
            <Input value={lessonIds} type="text" onChange={handleInputChange} />
          </MediaObject>
        </Row>
        <Divider size="small" />
        {!_.isEmpty(data) ? (
          <Row>
            <div className={styles['grid-wrap']}>
              <div className={styles.grid}>
                {data?.lessons.map((item) => <LessonBox data={item} key={item.id} hover={false} />)}
              </div>
            </div>
            <Divider size="small" />
            <Heading level={2} levelStyle={5}>
              Lesson Stats
            </Heading>
            <div className={styles['grid-wrap']}>
              <div className={styles.grid}>
                {data?.lessons.map((item) => {
                  const lessonStats = [
                    {
                      label: 'ID',
                      value: item.id,
                    },
                    {
                      label: 'Title',
                      value: item.videoTitle,
                    },
                    {
                      label: 'Total Duration',
                      value: `${Math.ceil(item.duration)} mins`,
                    },
                    {
                      label: 'Total users who watched this class',
                      value: `${Math.ceil(item.total_users_watched)} users`,
                    },
                    {
                      label: 'Avg view duration',
                      value: `${Math.ceil(item.avg_duration_per_lesson / 60)} mins`,
                    },
                    {
                      label: 'Avg percentage viewed',
                      value: `${item.avg_percentage_watched}%`,
                    },
                  ]

                  return (
                    <Box key={item.id} color="white">
                      {lessonStats.map(({ label, value }) => (
                        <Row size="small" key={label}>
                          <Text weight="bold">{label}: </Text>
                          <Text>{value}</Text>
                        </Row>
                      ))}
                    </Box>
                  )
                })}
              </div>
            </div>
            <Divider size="small" />
            <Heading level={2} levelStyle={5}>
              {' '}
              Bold Retention Graph{' '}
            </Heading>
            {!_.isEmpty(data?.latestTimestamp) ? (
              <AdminAnalyticsGraph data={data?.latestTimestamp} />
            ) : (
              <Text>No one has viewed this class yet</Text>
            )}
          </Row>
        ) : (
          <Heading level={5}> No Results </Heading>
        )}
      </View>
    </AdminLayout>
  )
}
