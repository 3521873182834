import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Buttons from 'components/Buttons'
import HeaderBack from 'components/HeaderBack'
import Heading from 'components/Heading'
import Layout from 'components/Layout'
import Modal from 'components/Modal'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import LessonFavoriteButton from 'features/lesson/components/LessonFavoriteButton'
import LessonFeedbackButton from 'features/lesson/components/LessonFeedbackButton'
import LessonScheduleButton from 'features/lesson/components/LessonScheduleButton'
import LessonTrainer from 'features/lesson/components/LessonTrainer'
import LessonVideo from 'features/lesson/components/LessonVideo'
import PostLesson from 'features/lesson/components/PostLesson'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as exploreApiRequest from 'libs/explore-api-request'
import * as url from 'libs/url'
import styles from './Lesson.module.scss'
import TransferDeviceButton from './components/TransferDeviceButton'
import { ReactComponent as ChairSvg } from './images/chair.svg'

type Props = {
  lessonId: string
}

export default function Lesson({ lessonId }: Props) {
  useEventPageVisited('Lesson', { lessonId })
  const globalContext = useGlobalContext()
  const location = useLocation()
  const [data, setData] = React.useState<any | undefined>()
  const [showPostLessonModal, setShowPostLessonModal] = React.useState<boolean | undefined>()

  React.useEffect(() => {
    // we check for a query lessonId in the url in case this is a desktop fallback
    const { source, lessonId: queryLessonId } = url.getQueryString()

    async function getData() {
      const response = await exploreApiRequest.getLessonForLessonId(lessonId ?? queryLessonId)

      const { data: lessonData } = response
      if (!lessonData || _.isEmpty(lessonData)) {
        navigate(paths.EXPLORE)
        return
      }

      setData(lessonData)

      globalContext.analytics?.trackEvent(events.OPEN_LESSON, {
        classType1: lessonData.classType?.[0],
        classType2: lessonData.classType?.[1],
        from: location?.state?.from,
        isCollectionLesson: location?.state?.from?.includes('playlist'),
        isProgramLesson: !!lessonData.exerciseProgramId,
        lessonId,
        lessonName: location?.state?.lessonTitle || lessonData.videoTitle,
        source,
        trainerName: lessonData.instructorName,
      })
    }
    getData()
    globalContext.update({ from: location.pathname })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.analytics, lessonId, location.pathname])

  function handleOnEnd() {
    setShowPostLessonModal(true)
  }

  function handleFeedbackSuccess() {
    setShowPostLessonModal(false)
  }

  const buttonProps = {
    level: 'text',
  }

  if (_.isEmpty(data)) {
    return null
  }

  const { id, instructions, requiredItems, videoLink, videoThumbnail, videoTitle } = data

  const hasRequiredItems = requiredItems && requiredItems.toLowerCase() !== 'none'

  return (
    <Layout className="Lesson">
      <View footer>
        <HeaderBack flush />
        <div className={styles.body}>
          <LessonVideo
            lesson={data}
            rounded
            thumbnail={videoThumbnail}
            url={videoLink}
            onEnd={handleOnEnd}
          />
          <Text align="left" element="div" className={styles.copy}>
            <Row size="xmedium">
              <div className={styles['copy--body']}>
                <Heading level={1} levelStyle={3}>
                  {location?.state?.lessonTitle || videoTitle}
                </Heading>
                <Row size="smallx">
                  <LessonTrainer data={data} />
                </Row>
                <Row size="small">
                  <LessonFavoriteButton lesson={data} lessonId={id} />
                </Row>
                <Text element="p" flush={!hasRequiredItems}>
                  {instructions}
                </Text>
                {hasRequiredItems && (
                  <>
                    <Text element="p" weight="bold">
                      To do this class, you may need:
                    </Text>
                    <TextWithIcon
                      icon={
                        requiredItems?.toLowerCase()?.includes('chair') ? <ChairSvg /> : undefined
                      }
                      inline>
                      {requiredItems.replaceAll(',', ', ')}
                    </TextWithIcon>
                  </>
                )}
              </div>
            </Row>
            <Buttons axis="y">
              <TransferDeviceButton lessonId={id} />
              <LessonFeedbackButton
                lesson={data}
                {...buttonProps}
                onFeedbackSuccess={handleFeedbackSuccess}
              />
              <LessonScheduleButton {...buttonProps} lessonId={id} />
            </Buttons>
          </Text>
        </div>
        <Modal isOpen={showPostLessonModal} onRequestClose={() => setShowPostLessonModal(false)}>
          <PostLesson
            lesson={data}
            onSuccess={() => {
              navigate(paths.EXPLORE)
            }}
          />
        </Modal>
      </View>
    </Layout>
  )
}
