import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import Container from 'components/Container'
import Field2 from 'components/Field2'
import Heading from 'components/Heading'
import Row from 'components/Row'
import View from 'components/View'
import statusCodes from 'constants/status-codes'
import AdminLayout from 'features/admin/components/AdminLayout'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as notifications from 'libs/notifications'
import * as validations from 'libs/validations'

export default function AdminCollectionCreate() {
  const [collectionId, setCollectionId] = React.useState('')

  async function handleSubmitCollection(values, formikActions) {
    values.lessonIds = values.lessonIds.split(',').map((lessonId) => lessonId.trim('\n'))
    values.tags =
      values.tags === 'all'
        ? ['default']
        : values.tags !== 'clinical only'
          ? ['default', values.tags] // adding default means it's shown to everyone
          : [values.tags]
    const response = await adminApiRequest.createCollection(values)

    if (response.statusCode === statusCodes.SUCCESS) {
      notifications.notifySuccess(response.data.message)
      setCollectionId(response.data.collection.id)
    } else {
      notifications.notifyError(response.message)
    }

    formikActions.setSubmitting(false)
  }

  async function handleSubmitChallenge(values, formikActions) {
    if (collectionId) {
      values.collectionId = collectionId
    }

    const response = await adminApiRequest.createChallenge(values)

    if (response.statusCode === statusCodes.SUCCESS) {
      notifications.notifySuccess(response.data.message)
    } else {
      notifications.notifyError(response.message)
    }

    formikActions.setSubmitting(false)
  }

  return (
    <AdminLayout className="AdminCollectionCreate">
      <View>
        <Container align="left" size="small">
          <Heading level={2}>Create Collection</Heading>
          <Row size="small">
            <Formik
              initialValues={{
                title: '',
                description: '',
                tags: '',
                lessonIds: '',
              }}
              validationSchema={Yup.object({
                title: validations.REQUIRED,
                description: validations.REQUIRED,
                tags: validations.REQUIRED,
                lessonIds: validations.REQUIRED,
              })}
              onSubmit={handleSubmitCollection}>
              {(formikProps) => (
                <Form>
                  <Field2 label="Title" name="title" />
                  <Field2 label="Description" name="description" />
                  <Field2
                    label="Which members should see this collection?"
                    entity="tags"
                    name="tags"
                    type="select"
                    options={[
                      {
                        label: 'All members',
                        value: 'all',
                      },
                      {
                        label: 'Clinical (+ fitness)',
                        value: 'clinical',
                      },
                      {
                        label: 'Clinical only',
                        value: 'clinical only',
                      },
                      {
                        label: 'Prefers Standing',
                        value: 'standing',
                      },
                      {
                        label: 'Prefers Standing/Floor',
                        value: 'standing/floor',
                      },
                      {
                        label: 'Prefers Seated',
                        value: 'seated',
                      },
                      {
                        label: 'Indicated Back Pain',
                        value: 'back pain',
                      },
                      {
                        label: 'Indicated Arthritis',
                        value: 'arthritis',
                      },
                    ]}
                  />
                  <Field2
                    placeholder="e.g. 012fdce4-ef26-4538-ad38-fbd2df1c0145, c127d7f5-9988-4c22-9a9f-4f86cddf8eba..."
                    label="Lesson IDs (comma-separated in desired order) "
                    type="textarea"
                    name="lessonIds"
                    maxLength="unlimited"
                  />
                  <Button
                    id="createCollection"
                    disabled={formikProps.isSubmitting}
                    onClick={formikProps.submitForm}>
                    Create Collection
                  </Button>
                </Form>
              )}
            </Formik>
          </Row>
          <Heading level={2}>Create Challenge</Heading>
          <Formik
            initialValues={{
              name: '',
              description: '',
              disclaimer: '',
              unit: '',
              threshold: '',
              newProgramId: null,
              collectionId: collectionId || null,
              activatedAt: '',
              deactivatedAt: '',
            }}
            validationSchema={Yup.object({
              name: validations.REQUIRED,
              description: validations.REQUIRED,
              disclaimer: validations.REQUIRED,
              unit: Yup.string().oneOf(['lesson', 'blog', 'minutes']).required(),
              threshold: validations.NUMBER_POSITIVE_REQUIRED,
              newProgramId: Yup.string().nullable(),
              collectionId: Yup.string().nullable(),
              activatedAt: validations.REQUIRED,
              deactivatedAt: validations.REQUIRED,
            })}
            onSubmit={handleSubmitChallenge}>
            {(formikProps) => (
              <Form>
                <Field2 label="Name" name="name" />
                <Field2 label="Description" name="description" />
                <Field2 label="Disclaimer" name="disclaimer" />
                <Field2
                  label="Unit of challenge progress"
                  name="unit"
                  placeholder="e.g. number of lessons. or minutes watched"
                  type="select"
                  options={[
                    {
                      label: 'Number of lessons watched',
                      value: 'lesson',
                    },
                    {
                      label: 'Number of minutes watched',
                      value: 'minutes',
                    },
                    {
                      label: 'Number of blogs read',
                      value: 'blog',
                    },
                  ]}
                />
                <Field2
                  label="Threshold for challenge completion"
                  name="threshold"
                  placeholder="Number of lessons / minutes to complete the challenge"
                />
                <Field2
                  label="Date to start challenge on"
                  name="activatedAt"
                  placeholder="2022-08-28 00:00:00+00"
                />
                <Field2
                  label="Date to end challenge on"
                  name="deactivatedAt"
                  placeholder="2022-08-28 00:00:00+00"
                />
                <Field2
                  label="Collection ID to link member to upon joining challenge (optional)"
                  name="collectionId"
                  value={collectionId}
                />
                <Field2
                  label="Program ID to switch member to upon joining challenge (optional)"
                  name="newProgramId"
                />
                <Button
                  id="createChallenge"
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.submitForm}>
                  Create Challenge
                </Button>
              </Form>
            )}
          </Formik>
        </Container>
      </View>
    </AdminLayout>
  )
}
