import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Heading from 'components/Heading'
import Row from 'components/Row'
import View from 'components/View'
import { useGlobalContext } from 'contexts/GlobalContext'
import AdminLayout from 'features/admin/components/AdminLayout'
import * as date from 'libs/date'
import { useTrainerUpcomingLiveStreams } from 'libs/trainer-query'

export default function TrainerDashboard() {
  const globalContext = useGlobalContext()
  const { data: streamsData } = useTrainerUpcomingLiveStreams()
  const streams = streamsData?.data

  if (!streams) {
    return null
  }

  return (
    <AdminLayout className="TrainerDashboard">
      <View>
        <Container size="xxlarge">
          <Heading level={2} levelStyle={1}>
            Welcome {globalContext.user.firstName}!
          </Heading>
          <Heading level={5}>We love that you’re part of the Bold family 😊</Heading>
          <Row> </Row>
          <Heading level={3}>Live Class Schedule</Heading>
          <Heading level={5}>Message for the class</Heading>
          <Row>
            Hi and welcome to today’s live class! We are so excited to have you join us today. You
            can use this chat to introduce yourself and to ask any specific questions to the
            trainer. Please note that this chat will NOT be monitored during class. Today’s trainer
            will stay after class to answer any questions related to class. If you have any
            questions, feedback, or concerns regarding Bold please reach out to Bold’s Member
            Experience team at hello@agebold.com or call us at 833-701-1545. You can also leave
            feedback after the class at https://www.agebold.com/app/live-lesson-feedback
          </Row>
          {streams.map((stream) => (
            <>
              <Box>
                <Row>
                  <Heading level={4}>
                    {date.format(stream.scheduledStartTs, 'eee MM/dd/yyyy h:mmaaa')}
                  </Heading>
                  <Heading level={4}>{stream.lesson?.videoTitle}</Heading>
                  <Heading level={5}>Zoom ID: {stream.zoomMeetingId}</Heading>
                  <Heading level={6}>{stream.lesson?.duration} minutes</Heading>
                </Row>
                <Button
                  onClick={() => {
                    window.location.href = `https://us06web.zoom.us/s/${stream.zoomMeetingId}`
                  }}>
                  Join Class
                </Button>
              </Box>
              <Row> </Row>
            </>
          ))}
        </Container>
      </View>
    </AdminLayout>
  )
}
