import classNames from 'classnames'
import React from 'react'
import ButtonWithModal from 'components/ButtonWithModal'
import ModalBox from 'components/ModalBox'
import TextWithIcon from 'components/TextWithIcon'
import type { Component } from 'constants/types'
import MagicLinkButton from './components/MagicLinkButton'
import { ReactComponent as CheckmarkCircleSvg } from './images/checkmark---circle.svg'
import monitorCircle from './images/monitor---circle.svg'
import { ReactComponent as MonitorStandAloneSvg } from './images/monitor---standalone.svg'

type Props = {
  className?: string
  lessonId: string
} & Component

export default function TransferDeviceButton({ className, lessonId }: Props) {
  const [isEmailSent, setIsEmailSent] = React.useState(false)

  if (!/android|iphone/i.test(navigator.userAgent)) return null

  return (
    <ButtonWithModal
      icon={<MonitorStandAloneSvg />}
      modal={
        <ModalBox
          image={monitorCircle}
          title="Want to open Bold on another device?"
          subtext={
            isEmailSent
              ? 'Check your email on the device you’d like to run Bold on. You can now close this modal.'
              : 'We will send you an email reminder to open Bold on your desktop computer. The link will expire in  10 minutes.'
          }>
          {isEmailSent ? (
            <TextWithIcon icon={<CheckmarkCircleSvg />} inline weight="bold">
              Email sent
            </TextWithIcon>
          ) : (
            <MagicLinkButton lessonId={lessonId} onEmailSent={() => setIsEmailSent(true)} />
          )}
        </ModalBox>
      }
      className={classNames('TransferDeviceButton', className)}
      onClose={() => setIsEmailSent(false)}>
      Transfer device
    </ButtonWithModal>
  )
}
