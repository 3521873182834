import _ from 'lodash'
import React from 'react'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as challengeLib from 'libs/challenge'
import type { UserChallenge } from 'libs/challenge'
import type { Lesson, LessonStream } from 'libs/lesson'
import { getLessonById } from 'libs/lesson-api-request'
import { useLiveLessons } from 'libs/live-lessons-query'
import * as logging from 'libs/logging'
import * as userApiRequest from 'libs/user-api-request'
import DashboardTodayLesson from '../DashboardTodayLesson'

type Props = {
  challenge: UserChallenge
}

export default function DashboardTodayLessonMove({ challenge }: Props) {
  const globalContext = useGlobalContext()
  const { data: liveLessons, isLoading: isLoadingLiveLessons } = useLiveLessons({
    revalidateOnFocus: false,
  })
  const upcomingLiveLessons = liveLessons?.data

  const [lesson, setLesson] = React.useState<Lesson | {}>({})
  const [liveLessonStream, setLiveLessonStream] = React.useState<LessonStream | null>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    async function getLesson() {
      // exit early if challenge already completed bc lesson doesn't matter
      if (challenge?.progress === 1 || challenge?.complete) {
        setIsLoading(false)
        return
      }

      // check if should override with scheduled live class today
      if (!_.isEmpty(upcomingLiveLessons)) {
        const lessonsScheduled = upcomingLiveLessons.filter((item: LessonStream) => {
          if (!item.scheduledLessons?.id || item.scheduledLessons?.status === 'canceled') {
            return false
          }
          const startTime = new Date(item.scheduledStartTs)
          const now = new Date()
          const isSameDate = startTime.toDateString() === now.toDateString()
          const isClassNotOver =
            startTime.getTime() + item.lesson.duration * 60 * 1000 >= now.getTime()
          return isSameDate && isClassNotOver
        })
        if (!_.isEmpty(lessonsScheduled)) {
          const lessonScheduled = lessonsScheduled[0]
          setLiveLessonStream(lessonScheduled)
          setLesson(lessonScheduled?.lesson)
          setIsLoading(false)
          return
        }
      }

      const moveLessonId = challenge?.lessonId
      let lessonToSet = null
      if (moveLessonId) lessonToSet = await getLessonById(moveLessonId)

      if (!_.isNil(lessonToSet) && !_.isEmpty(lessonToSet.data)) {
        setLesson(lessonToSet.data)
        setLiveLessonStream(null)
        setIsLoading(false)
        return
      }

      const response = await userApiRequest.getNextProgramLessonForUser()
      if (response.statusCode === 200 && !_.isNil(response?.data) && !_.isEmpty(response?.data)) {
        setLesson(response?.data[0])
      } else {
        logging.toDatadog(
          `DashboardTodayLessonMove: no move challenge today for ${globalContext?.user?.id}!`
        )
        await globalContext.analytics?.trackEvent('No move challenge today')
      }

      setIsLoading(false)
    }

    getLesson()
  }, [
    challenge,
    globalContext.analytics,
    globalContext?.user?.id,
    isLoadingLiveLessons,
    upcomingLiveLessons,
  ])

  if (_.isNil(challenge) || _.isEmpty(challenge)) {
    return null
  }

  return (
    <DashboardTodayLesson
      key={lesson?.id}
      challenge={challenge}
      challengeTag={challengeLib.CHALLENGE_TAG.MOVE}
      lesson={lesson}
      liveLesson={challenge.complete ? undefined : liveLessonStream}
      loading={isLoading}
      className="DashboardTodayLessonMove"
    />
  )
}
