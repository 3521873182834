import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { navigate } from 'gatsby'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Row from 'components/Row'
import Text from 'components/Text'
import experiments from 'constants/experiments'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingLayout from 'features/onboarding/components/OnboardingLayout'
import OnboardingView from 'features/onboarding/components/OnboardingView'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as user from 'libs/user'
import { useUserGoal } from 'libs/user-query'

const convertTo12HourFormat = (militaryTime: string) => {
  if (!militaryTime) return 'Invalid time'

  const [hour, minute] = militaryTime.split(':').map(Number)
  const period = hour < 12 ? ' AM' : ' PM'
  const adjustedHour = hour % 12 || 12

  return (
    <>
      {adjustedHour}:{minute.toString().padStart(2, '0')}
      <Text element="span" size="medium">
        {period}
      </Text>
    </>
  )
}

const getDayAbbreviation = (day: string) => {
  const dayMap: { [key: string]: string } = {
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thurs',
    Friday: 'Fri',
    Saturday: 'Sat',
    Sunday: 'Sun',
  }
  return dayMap[day] || day
}

export default function OnboardingCommitment() {
  useEventPageVisited('OB commitment')
  const { data: userGoalData } = useUserGoal({})
  const isSpecial = user.hasHomeSpecial()
  const isVariant = useFeatureIsOn(experiments.COMMITMENT_COPY_EXPERIMENT)
  const globalContext = useGlobalContext()

  async function handleClick() {
    await globalContext.analytics?.trackEvent('OB Commitment: button click')
    if (isSpecial) {
      navigate(paths.PROGRAM, { replace: true })
    } else {
      navigate(paths.HOME_AUTHED, { replace: true })
    }
  }

  return (
    <OnboardingLayout redirectIfOnboardingComplete={false}>
      <OnboardingView
        title={isVariant ? 'Here’s what you signed up for' : 'Commit to your healthy habit goals'}>
        <Row size="smallx">
          <Text element="p">
            {isVariant
              ? 'Bold works best when its part of your routine.'
              : 'Consistency makes habits stick.'}{' '}
            You’ll receive encouraging nudges through email
            {userGoalData?.data?.reminderSmsConsent && ' and text'} when it's time to take your next
            class.
          </Text>
          {userGoalData?.data?.reminderTimeExact &&
            userGoalData?.data?.reminderDays?.length > 0 && (
              <Row size="smallx">
                <Box color="white" size="medium">
                  <Row size="xxsmall">
                    <Text size="xxlarge">
                      {convertTo12HourFormat(userGoalData?.data?.reminderTimeExact)}
                    </Text>
                  </Row>
                  <Text size="medium">
                    Every{' '}
                    {userGoalData?.data?.reminderDays
                      .map((day: string) => getDayAbbreviation(day))
                      .join(', ') || []}
                  </Text>
                </Box>
              </Row>
            )}
          <Row size="small">
            <Text size="small">You can update your reminder preferences any time.</Text>
          </Row>
          <Button level="primary" full onClick={handleClick}>
            {isVariant ? 'Get started' : 'I commit'}
          </Button>
        </Row>
      </OnboardingView>
    </OnboardingLayout>
  )
}
