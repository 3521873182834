import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Container from 'components/Container'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Text from 'components/Text'
import Title from 'components/Title'
import View from 'components/View'
import AssessmentCards from 'features/assessments/components/AssessmentCards'
import styles from './Assessments.module.scss'

//import { ReactComponent as HeroSvg } from './images/hero.svg'

export default function Assessments() {
  return (
    <Layout className="Assessments">
      <View>
        {/*<HeroSvg className={styles['hero-image']} />*/}
        <StaticImage
          src="./images/hero@2x.png"
          alt=""
          layout="fullWidth"
          options={{
            quality: 100,
          }}
          className={classNames(styles['hero-image'], styles['hero-image---large'])}
        />
        <StaticImage
          src="./images/hero---small@2x.png"
          alt=""
          layout="fullWidth"
          options={{
            quality: 100,
          }}
          className={classNames(styles['hero-image'], styles['hero-image---small'])}
        />
        <Row>
          <Container flush size="xlarge">
            <Text color="white" element="header">
              <Container align="left" flush size="medium">
                <Title
                  subtext="Assess your current fitness level, and track your progress over time."
                  align="left"
                  flush
                  size="xlarge">
                  Let’s check in.
                </Title>
              </Container>
            </Text>
          </Container>
        </Row>
        <Row size="xxlarge">
          <AssessmentCards />
        </Row>
      </View>
    </Layout>
  )
}
