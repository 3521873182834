import React from 'react'
import Container from 'components/Container'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import ModalConfirm from 'components/ModalConfirm'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import CheckinSingleForm from 'features/checkin/components/CheckinSingleForm'
import * as checkinApiRequest from 'libs/checkin-api-request'
import CheckinTracker from 'libs/checkins/checkin-tracker'

export default function NpsModal() {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker>(null)
  const [hideClose, setHideClose] = React.useState(false)
  const [showCheckin, setShowCheckin] = React.useState(false)
  const [showConfirmation, setShowConfirmation] = React.useState(false)

  React.useEffect(() => {
    async function getCheckin() {
      if (!globalContext.user || globalContext.isVideoPlaying) return

      const checkinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'nps',
          code: 'nps_default',
          activeOnly: true,
          createIfNotExists: false,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'nps',
            type: 'nps',
          })
        })

      if (checkinResponse.data) {
        setCheckinTracker(
          new CheckinTracker(globalContext.user.id, checkinResponse.data, globalContext.analytics)
        )
        setShowCheckin(true)
        globalContext.analytics?.trackEvent('NPS Checkin shown', {
          checkinId: checkinResponse.data.id,
        })
      }
    }

    getCheckin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.isVideoPlaying])

  function closeModal() {
    setShowCheckin(false)
  }

  function closeConfirmModal() {
    setShowConfirmation(false)
    closeModal() // to make sure csat modal also closes...
  }

  function handleManualClose() {
    // dismiss on close
    checkinApiRequest.markUserCheckinAsDismissed(checkinTracker.checkinId)
    globalContext.analytics?.trackEvent('NPS Checkin manually closed', {
      checkinId: checkinTracker.checkinId,
    })
    closeModal()
  }

  function handleSuccess() {
    closeModal()
    setShowConfirmation(true)
  }

  const commonProps = {
    className: 'NpsModal',
  }

  if (showConfirmation) {
    return (
      <ModalConfirm
        {...commonProps}
        title="Thank you for your responses"
        confirmText="Close"
        hideCancel
        hideClose
        isOpen
        onConfirm={closeConfirmModal}
        onRequestClose={closeConfirmModal}
      />
    )
  }

  return (
    <Modal
      {...commonProps}
      hideClose={hideClose}
      isOpen={showCheckin}
      shouldCloseOnOverlayClick={false}
      onRequestClose={handleManualClose}>
      <ModalBox title="Let’s check in">
        <Container flush size="medium">
          <CheckinSingleForm
            buttonProps={{
              children: 'Send feedback',
              full: true,
            }}
            checkinTracker={checkinTracker}
            size="small"
            onResponse={() => setHideClose(true)}
            onSuccess={handleSuccess}
          />
        </Container>
      </ModalBox>
    </Modal>
  )
}
