import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import paths from 'constants/paths'
import PlaylistTags from 'features/playlists/components/PlaylistTags'
import * as lessonApiRequest from 'libs/lesson-api-request'
import ExploreLessons from '../ExploreLessons'

type Props = {
  id: string
}

export default function ExploreLessonsPlaylist({ id }: Props) {
  const [lessons, setLessons] = React.useState()
  const [playlist, setPlaylist] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    async function getLessons() {
      const response = await lessonApiRequest.getPlaylist(id).catch((error: any) => {
        console.error(error)
      })

      setIsLoading(false)

      let lessonsResponse = response.data?.lessons
      lessonsResponse = lessonsResponse.sort((a, b) => {
        if (a.userView?.status === 'completed' && b.userView?.status !== 'completed') return 1 // completed to end
        if (b.userView?.status === 'completed' && a.userView?.status !== 'completed') return -1 // keep non-completed earlier
        return 0 // keep ordering otherwise
      })
      setLessons(lessonsResponse)
      setPlaylist(response.data?.playlist)
    }
    getLessons()
  }, [id])

  function handleButtonClick() {
    navigate(`${paths.PLAYLIST}${id}/`, { state: { from: paths.EXPLORE } })
  }

  if (isLoading || _.isNil(playlist)) return null

  return (
    <ExploreLessons
      title={playlist.title}
      subtext={playlist.description}
      append={<PlaylistTags id={id} lessons={lessons} />}
      action={
        _.size(lessons) > 3 && <Button onClick={handleButtonClick}>View full collection</Button>
      }
      data={lessons}
      eventKey="collection"
      loading={isLoading}
      className="ExploreLessonsPlaylist"
    />
  )
}
