import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Loader from 'components/Loader'
import { useGlobalContext } from 'contexts/GlobalContext'
import ExploreSection, {
  type ExploreSectionProps,
} from 'features/explore/components/ExploreSection'
import LessonBox from 'features/lesson/components/LessonBox'
import constants from 'styles/constants.module.scss'
import styles from './ExploreLessons.module.scss'

type Props = {
  action?: React.ReactElement
  data: {}[]
  eventKey?: string
  loading?: boolean
} & Partial<ExploreSectionProps>

export default function ExploreLessons({
  action,
  className,
  data,
  eventKey,
  loading = true,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const { width } = useWindowSize()

  function handleActionClick(onClick: () => void) {
    if (onClick) onClick()
    if (!eventKey) return
    globalContext.analytics.trackEvent(`Explore landing ${eventKey} carousel show all button click`)
  }

  if (!loading && _.isEmpty(data)) return null

  return (
    <ExploreSection
      {...props}
      action={
        !!action &&
        _.size(data) > 3 &&
        React.cloneElement(action, {
          children: action.props?.children || 'Show more',
          color: 'white',
          onClick: () => handleActionClick(action.props?.onClick),
        })
      }
      className={classNames('ExploreLessons', className)}>
      {loading ? (
        <Loader overlay={false} className={styles.loader} />
      ) : (
        data
          .slice(0, 3)
          .map((item) => (
            <LessonBox data={item} hover={width > Number(constants.LT_FULL_PX)} key={item.id} />
          ))
      )}
    </ExploreSection>
  )
}
