import React from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

type RetentionDataPoint = {
  time: number
  users_retained: number
}

type LessonData = {
  lesson: {
    id: string
    videoTitle: string
  }
  retentionData: RetentionDataPoint[][]
}

export type GraphProps = {
  data: Record<string, LessonData>
}

export default function AdminAnalyticsGraph({ data }: GraphProps) {
  // Convert nested data format to a structured array for Recharts
  const formattedData: { [key: number]: Record<string, number> }[] = []

  // Extract lessonIds and assign each a color
  const lessonIds = Object.keys(data)
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#ff6384', '#36a2eb']

  // Process each lesson's data
  lessonIds.forEach((lessonId) => {
    const lessonInfo = data[lessonId].lesson // Extract lesson metadata
    const lessonData = data[lessonId].retentionData.flat() // Flatten retentionData array

    lessonData.forEach(({ time, users_retained }) => {
      // Find or create an entry for this time value
      let entry = formattedData.find((d) => d.time === time)
      if (!entry) {
        entry = { time }
        formattedData.push(entry)
      }

      // Store user retention percentage under lessonId key
      entry[lessonId] = users_retained

      // Store the lesson name in a separate field
      entry[`${lessonId}_name`] = lessonInfo?.videoTitle || 'Unknown Lesson'
    })
  })

  // Sort data by time (ensures correct X-axis representation)
  formattedData.sort((a, b) => a.time - b.time)

  return (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        data={formattedData}
        margin={{
          top: 20,
          right: 30,
          left: 30,
          bottom: 20,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          label={{
            value: 'Timestamp (minutes)',
            position: 'insideBottom',
            offset: -5,
          }}
        />
        <YAxis
          domain={[0, 'dataMax']}
          tickFormatter={(tick) => `${tick}%`}
          label={{
            value: 'Users Retained (%)',
            angle: -90,
            position: 'insideLeft',
          }}
        />
        <Tooltip />
        <Legend
          layout="horizontal"
          align="center"
          verticalAlign="top"
          wrapperStyle={{ paddingBottom: '10px' }}
        />

        {lessonIds.map((lessonId, index) => (
          <Line
            key={lessonId}
            type="monotone"
            dataKey={lessonId}
            stroke={colors[index % colors.length]}
            strokeWidth={2}
            dot={{ r: 3 }}
            name={formattedData[0]?.[`${lessonId}_name`] || lessonId} // Extract lesson name
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}
